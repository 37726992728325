import React from 'react';

import {
    Route,
} from "react-router-dom";

import { Body } from 'PathWWW/components/Body.jsx';
import { DataLoaderProvider } from 'PathCore/contexts/DataLoaderContext.jsx';



export const App = (props) => {

    return (
        <Route
            path="/"
            exact={false}
            render={mainRouteProps => (
                <DataLoaderProvider {...mainRouteProps}>
                    <Body {...mainRouteProps} />
                </DataLoaderProvider>
            )}
        />
    );

};
