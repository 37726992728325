//
// Hook permettant de gérer un cookie
//
export const useCookie = (cookieName, defaultValue) => {

    // Tous les cookies sont accessibles sur tout le site
    const PATH = "/";


    ////////////////////////////////////////
    //                                    //
    //          Méthodes internes         //
    //                                    //
    ////////////////////////////////////////

    // Méthode permettant de récupérer la valeur associée au cookie ayant pour nom 'name'
    // Si le cookie n'est pas trouvé, alors la valeur 'undefined' est renvoyée
    const __getValue = (name) => {

        try {
            // On recherche le cookie ayant pour nom 'name',
            // c'est-à-dire la position de la sous-chaîne commençant par 'name='
            // Cette recherche s'effectue dans toutes les sous-chaines séparées par des '; '
            const result = document.cookie
                .split('; ')
                .find(item => item.startsWith(name + '='));

            // Est-ce que cette sous-chaine a été trouvée ?
            if(result) {
                // OUI : le cookie existe
                // Dans ce cas, on retourne la valeur, située à droite du '='
                return JSON.parse(result.split('=')[1]);
            }

        } catch(error) {
            // Impossible de lire le cookie : on fait comme s'il n'existait pas !
            return undefined;
        }

        // NON : le cookie n'existe pas
        return undefined;
    }


    // Écrit un nouveau cookie ayant pour nom 'name' et pour valeur 'value'
    // La durée de validité, exprimée en secondes, est définie par 'duration'
    const __writeCookie = (name, value, duration=undefined, path=undefined) => {

        // On calcule la date de fin de validité du cookie en fonction du paramètre 'duration'
        // Si aucune 'duration' passée en paramètre alors le cookie sera un cookie de session
        let strExpires = "";
        if(duration) {
            let expireDate = new Date();
            expireDate.setTime(expireDate.getTime() + (duration * 1000));
            strExpires = "expires=" + expireDate.toUTCString();
        }

        // On calcule la valeur de 'path' en fonction du paramètre 'path'
        let strPath = path ? "path=" + path : "";

        // On écrit le cookie
        document.cookie = name + "=" + JSON.stringify(value) + (strExpires && "; " + strExpires) + (strPath && "; " + strPath);
    }


    // Méthode permettant de supprimer un cookie
    const __eraseCookie = (name, path=undefined) => {

        // On calcule la valeur de 'path' en fonction du paramètre 'path'
        let strPath = path ? "path=" + path : "";

        // Puis on efface la valeur actuelle du cookie
        // et on fixe sa date d'expiration à une date déjà passée
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (strPath && "; " + strPath);
    }



    ////////////////////////////////
    //                            //
    //          Interface         //
    //                            //
    ////////////////////////////////

    // Méthode permettant de créer/modifier la valeur du cookie
    // ainsi que sa durée de validité
    const getCookie = () => {
        // On lit la valeur du cookie, ou on retourne la valeur
        // par défaut si le cookie n'est pas présent
        return (__getValue(cookieName) || defaultValue);
    };


    // Méthode permettant de créer/modifier la valeur du cookie
    // ainsi que sa durée de validité
    const setCookie = (cookieValue, cookieDuration) => {
        // On écrit le cookie
        __writeCookie(cookieName, cookieValue, cookieDuration, PATH);
    };

    // Méthode permettant de supprimer le cookie
    const deleteCookie = () => {
        // On efface le cookie
        __eraseCookie(cookieName, PATH);
    };


    // On retourne :
    // - la méthode à utiliser pour lire le cookie
    // - la méthode à utiliser pour modifier le cookie
    // - la méthode à utiliser pour supprimer le cookie
    return [getCookie, setCookie, deleteCookie];
};
