import React from 'react';
import PropTypes from 'prop-types';

import {
    useState,
} from 'react';

import {
    Route,
    Link,
} from "react-router-dom";

import {
    AppBar,
    Box,
    Divider,
    Hidden,
    IconButton,
    List,
    SwipeableDrawer,
    Toolbar,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';

import {
    merge as lodashMerge
} from 'lodash';


import { MainMenu } from "PathWWW/components/MainMenu.jsx";
import { LstMenuDrawer } from 'PathWWW/components/LstMenusDrawer.jsx';





// Style propre au composant
const useHeaderStyles = () => makeStyles((theme) => ({

    headerAppBar: style => ({
        ...style.headerAppBar.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerAppBar.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerAppBar.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerAppBar.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerAppBar.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerAppBar.xl,
        },
    }),

    headerToolbar: style => ({
        ...style.headerToolbar.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbar.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbar.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbar.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbar.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbar.xl,
        },
    }),

    headerHiddenSMDown: style => ({
        ...style.headerHiddenSMDown.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerHiddenSMDown.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerHiddenSMDown.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerHiddenSMDown.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerHiddenSMDown.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerHiddenSMDown.xl,
        },
    }),

    headerHiddenMDUp: style => ({
        ...style.headerHiddenMDUp.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerHiddenMDUp.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerHiddenMDUp.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerHiddenMDUp.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerHiddenMDUp.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerHiddenMDUp.xl,
        },
    }),

    headerDrawer: style => ({
        ...style.headerDrawer.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawer.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawer.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawer.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawer.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawer.xl,
        },
    }),

    headerDrawerHeader: style => ({
        ...style.headerDrawerHeader.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeader.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeader.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeader.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeader.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeader.xl,
        },
    }),

    headerDrawerHeaderLink: style => ({
        ...style.headerDrawerHeaderLink.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeaderLink.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeaderLink.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeaderLink.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeaderLink.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeaderLink.xl,
        },
    }),

    headerDrawerHeaderBox: style => ({
        ...style.headerDrawerHeaderBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeaderBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeaderBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeaderBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeaderBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeaderBox.xl,
        },
    }),

    headerDrawerHeaderIcon: style => ({
        ...style.headerDrawerHeaderIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeaderIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeaderIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeaderIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeaderIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeaderIcon.xl,
        },
    }),

    headerDrawerHeaderLabelsBox: style => ({
        ...style.headerDrawerHeaderLabelsBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeaderLabelsBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeaderLabelsBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeaderLabelsBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeaderLabelsBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeaderLabelsBox.xl,
        },
    }),

    headerDrawerHeaderLabel1: style => ({
        ...style.headerDrawerHeaderLabel1.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeaderLabel1.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeaderLabel1.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeaderLabel1.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeaderLabel1.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeaderLabel1.xl,
        },
    }),

    headerDrawerHeaderLabel2: style => ({
        ...style.headerDrawerHeaderLabel2.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerHeaderLabel2.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerHeaderLabel2.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerHeaderLabel2.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerHeaderLabel2.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerHeaderLabel2.xl,
        },
    }),

    headerDrawerMainList: style => ({
        ...style.headerDrawerMainList.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerDrawerMainList.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerDrawerMainList.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerDrawerMainList.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerDrawerMainList.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerDrawerMainList.xl,
        },
    }),

    headerToolbarMobileMode: style => ({
        ...style.headerToolbarMobileMode.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileMode.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileMode.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileMode.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileMode.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileMode.xl,
        },
    }),

    headerToolbarMobileModeIcon: style => ({
        ...style.headerToolbarMobileModeIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileModeIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileModeIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileModeIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileModeIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileModeIcon.xl,
        },
    }),

    headerToolbarMobileModeLogoLink: style => ({
        ...style.headerToolbarMobileModeLogoLink.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileModeLogoLink.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileModeLogoLink.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileModeLogoLink.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileModeLogoLink.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileModeLogoLink.xl,
        },
    }),

    headerToolbarMobileModeLogoLinkIcon: style => ({
        ...style.headerToolbarMobileModeLogoLinkIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileModeLogoLinkIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileModeLogoLinkIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileModeLogoLinkIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileModeLogoLinkIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileModeLogoLinkIcon.xl,
        },
    }),

    headerToolbarMobileModeLabelsBox: style => ({
        ...style.headerToolbarMobileModeLabelsBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileModeLabelsBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileModeLabelsBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileModeLabelsBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileModeLabelsBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileModeLabelsBox.xl,
        },
    }),

    headerToolbarMobileModeLabel1: style => ({
        ...style.headerToolbarMobileModeLabel1.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileModeLabel1.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileModeLabel1.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileModeLabel1.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileModeLabel1.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileModeLabel1.xl,
        },
    }),

    headerToolbarMobileModeLabel2: style => ({
        ...style.headerToolbarMobileModeLabel2.common,

        [theme.breakpoints.only('xs')]: {
            ...style.headerToolbarMobileModeLabel2.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.headerToolbarMobileModeLabel2.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.headerToolbarMobileModeLabel2.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.headerToolbarMobileModeLabel2.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.headerToolbarMobileModeLabel2.xl,
        },
    }),

}));





//
// Composant représentant le 'header'.
// On y retrouve notamment la barre de menu
//
export const Header = ({ header, menu }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Permet de gérer l'état du 'drawer' (ouvert ou fermé)
    const [isOpenDrawer, setIsOpenDrawer] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque le "drawer" doit être ouvert
    const handleDrawerOpen = () => {
        setIsOpenDrawer(true);
    };


    // Callback appelée lorsque le "drawer" doit être fermé
    const handleDrawerClose = () => {
        setIsOpenDrawer(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useHeaderStyles()(
        lodashMerge(
            {
                headerAppBar: {
                    common: {
                        minHeight: '5vh',
                        backgroundColor: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbar: {
                    common: {
                        minHeight: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerHiddenSMDown: {
                    common: {
                        width: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerHiddenMDUp: {
                    common: {
                        width: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawer: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeader: {
                    common: {
                        display: 'flex',
                        alignItems: 'center',
                        padding: theme.spacing(0, 1),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeaderLink: {
                    common: {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        padding: theme.spacing(1),
                        justifyContent: 'flex-start',
                        textDecoration: 'none',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeaderBox: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeaderIcon: {
                    common: {
                        width: 'auto',
                        height: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeaderLabelsBox: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingRight: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                        height: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeaderLabel1: {
                    common: {
                        whiteSpace: 'nowrap',
                        fontSize: '1rem',
                        fontFamily: 'Satisfy',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerHeaderLabel2: {
                    common: {
                        whiteSpace: 'nowrap',
                        fontSize: '1.6rem',
                        fontWeight: 'bold',
                        fontFamily: 'Satisfy',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerDrawerMainList: {
                    common: {
                        width: '80vw',
                        paddingRight: theme.spacing(2),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileMode: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileModeIcon: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileModeLogoLink: {
                    common: {
                        textDecoration: 'none',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileModeLogoLinkIcon: {
                    common: {
                        width: 'auto',
                        height: '3rem',
                        padding: '0.1rem',
                        margin: '0.3rem',
                        backgroundColor: 'white',
                        borderRadius: '10%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileModeLabelsBox: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingRight: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                        height: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileModeLabel1: {
                    common: {
                        whiteSpace: 'nowrap',
                        fontSize: '1rem',
                        fontFamily: 'Satisfy',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                headerToolbarMobileModeLabel2: {
                    common: {
                        whiteSpace: 'nowrap',
                        fontSize: '1.6rem',
                        fontWeight: 'bold',
                        fontFamily: 'Satisfy',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

            },
            header.style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <AppBar
            position="sticky"
            className={classes.headerAppBar}
        >
            <Toolbar
                className={classes.headerToolbar}
            >
                {/* Le menu n'est visible qu'en mode 'md', 'lg' ou 'xl' */}
                <Hidden
                    className={classes.headerHiddenSMDown}
                    smDown
                    implementation="css"
                >
                    <Route
                        path="/"
                        render={(propsRoute) =>
                            <MainMenu
                                {...propsRoute}
                                logo={header && header.logo}
                                label={header && header.label}
                                params={header && header.params}
                                style={header && header.style}
                                menu={menu}
                            />
                        }
                    />
                </Hidden>

                {/* Le bouton ouvrant le 'drawer' n'est visible qu'en mode 'xs' ou 'sm' */}
                <Hidden
                    className={classes.headerHiddenMDUp}
                    mdUp
                    implementation="css"
                >
                    <Box
                        className={classes.headerToolbarMobileMode}
                    >
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            edge="start"
                            className={classes.headerToolbarMobileModeIcon}
                        >
                            <MenuIcon />
                        </IconButton>

                        {header && header.logo &&
                            <Link
                                className={classes.headerToolbarMobileModeLogoLink}
                                to="/"
                            >
                                <img
                                    className={classes.headerToolbarMobileModeLogoLinkIcon}
                                    src={header.logo.src}
                                    srcSet={header.logo.srcset}
                                    sizes="10vw"
                                    title={`${header.logo.legend} - ${header.logo.credits}`}
                                    alt={header.logo.alt}
                                />
                            </Link>
                        }

                        {header && header.params && (header.params.label1 || header.params.label2) &&
                            <Box className={classes.headerToolbarMobileModeLabelsBox}>
                                {header.params.label1 &&
                                    <Typography className={classes.headerToolbarMobileModeLabel1}>
                                        {header.params.label1}
                                    </Typography>
                                }
                                {header.params.label2 &&
                                    <Typography className={classes.headerToolbarMobileModeLabel2}>
                                        {header.params.label2}
                                    </Typography>
                                }
                            </Box>
                        }
                    </Box>
                </Hidden>
            </Toolbar>

            {/* Le 'drawer' n'est visible qu'en mode 'xs' ou 'sm' */}
            <Hidden mdUp implementation="css">
                <SwipeableDrawer
                    className={classes.headerDrawer}
                    anchor="left"
                    open={isOpenDrawer}
                    onClick={handleDrawerClose}
                    onOpen={handleDrawerOpen}
                    onClose={handleDrawerClose}
                >
                    <Box
                        className={classes.headerDrawerHeader}
                    >
                        <Link
                            className={classes.headerDrawerHeaderLink}
                            to="/"
                        >
                            <Box className={classes.headerDrawerHeaderBox}>
                                {header && header.logo &&
                                    <img
                                        className={classes.headerDrawerHeaderIcon}
                                        src={header.logo.src}
                                        srcSet={header.logo.srcset}
                                        sizes="10vw"
                                        title={`${header.logo.legend} - ${header.logo.credits}`}
                                        alt={header.logo.alt}
                                    />
                                }

                                {header && header.params && (header.params.label1 || header.params.label2) &&
                                    <Box className={classes.headerDrawerHeaderLabelsBox}>
                                        {header.params.label1 &&
                                            <Typography className={classes.headerDrawerHeaderLabel1}>
                                                {header.params.label1}
                                            </Typography>
                                        }
                                        {header.params.label2 &&
                                            <Typography className={classes.headerDrawerHeaderLabel2}>
                                                {header.params.label2}
                                            </Typography>
                                        }
                                    </Box>
                                }

                            </Box>
                        </Link>
                        <IconButton onClick={handleDrawerClose}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Divider />

                    <List className={classes.headerDrawerMainList}>
                        <LstMenuDrawer
                            menu={menu}
                        />
                    </List>
                </SwipeableDrawer>
            </Hidden>
        </AppBar>
    );
}

Header.propTypes = {
    header: PropTypes.object,
    menu: PropTypes.object,
};
