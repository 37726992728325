import {
    useState,
    useEffect,
    useReducer,
} from 'react';





//
// Énumération utilisée par le hook 'useRequestData' pour indiquer les différents statuts
//
export const RequestStatus = Object.freeze({
    IDLE: 0,
    IN_PROGRESS: 1,
    SUCCESS: 2,
    ERROR: 3,
    CANCELED: 4,
});



//
// Liste des actions gérées par le 'reducer'
//
const RequestActions = Object.freeze({
    FETCH: 0,
    SUCCESS: 1,
    ERROR: 2,
    ABORT: 3,
    RESET: 4,
});



//
// Hook permettant de demander des données au serveur
//
// Paramètres :
//   - initialData : données initiales, avant que la moindre requête n'ait été exécutée
//   - mappingFunc : callback appelée sur les données avant de les retourner. Par défaut,
//                   contient la fonction identité pour ne pas modifier les données
//
export const useRequestData = ({ url=null, initialData=null, options=null, mapFunc=null } = {}) => {

    // On sauvegarde les paramètres passés au hook
    const [params, setParams] = useState({
        url: url,
        initialData: initialData,
        options: options,
        mapFunc: mapFunc,
    });


    // 'controller' sur la requête, utilisé pour demander l'annulation de celle-ci
    const controller = new AbortController();


    // Valeurs initiales
    const initialState = {
        status: RequestStatus.IDLE,
        response: null,
        options: null,
        data: initialData,
        signal: controller.signal,
    };


    // Méthode permettant d'initialiser le 'reducer'
    const initReducer = (initialState) => {
        return initialState;
    }


    // 'reducer' utilisé pour mettre à jour les données
    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {

            // Une requête vient d'être envoyée : on attend la réponse...
            case RequestActions.FETCH:
                return {
                    ...state,
                    status: RequestStatus.IN_PROGRESS,
                    response: null,
                    options: action.payload.options,
                };

            case RequestActions.SUCCESS:
                return {
                    ...state,
                    status: RequestStatus.SUCCESS,
                    response: action.payload.response,
                    data: action.payload.data,
                };

            case RequestActions.ERROR:
                return {
                    ...state,
                    status: RequestStatus.ERROR,
                    response: action.payload.response,
                    data: null,
                };

            case RequestActions.ABORT:
                return {
                    ...state,
                    status: RequestStatus.CANCELED,
                    response: null,
                    data: null,
                };

            // La requête doit être réinitialisée
            case RequestActions.RESET:
                return initReducer(initialState);

            // Dans tous les autres cas : ERREUR !
            default:
                throw new Error();
        }
    }, initialState, initReducer);


    // Hook appelé lorsqu'un des paramètres a changé
    useEffect(() => {

        // Méthode ASYNCHRONE utilisée pour dialoguer avec le serveur
        const fetchRequestInner = async () => {
            // On ré-initialise la requête
            dispatch({type: RequestActions.RESET});

            // On met à jour le statut
            dispatch({type: RequestActions.FETCH, payload: {options: params.options}});

            try {
                // On demande les données et on attend la réponse du serveur
                const response = await fetch(params.url, {...params.options, signal: state.signal});

                if(response.ok) {
                    const body = await response.json();

                    // On sauvegarde les données et on indique que tout s'est bien passé
                    dispatch({type: RequestActions.SUCCESS, payload: {response: response, data: params.mapFunc ? params.mapFunc(body) : body}});
                } else {
                    dispatch({type: RequestActions.SUCCESS, payload: {response: response, data: null}});
                }

            } catch(error) {
                // Une erreur est survenue : on change le statut et on sauvegarde l'erreur
                dispatch({type: RequestActions.ERROR, payload: {response: error}});
            }
        };

        // On n'interroge le serveur que si une URL a été précisée
        if(params.url) {
            fetchRequestInner();
        }

    }, [params]);




    /////////////////////////////////
    //                             //
    //          Interface          //
    //                             //
    /////////////////////////////////

    // Méthode permettant de lancer une nouvelle requête
//    const fetchRequest = (url, initialData=null, options=null, mapFunc=null) => {
    const fetchRequest = ({ url=null, initialData=null, options=null, mapFunc=null } = {}) => {
        setParams({
            url: url,
            initialData: initialData,
            options: options,
            mapFunc: mapFunc,
        })
    }


    // Méthode permettant d'annuler la requête actuelle
    const abortRequest = () => {
        controller.abort();
        dispatch({
            type: RequestActions.ABORT,
        });
    }


    // Méthode permettant de réinitialiser la requête actuelle (statut, données associées...)
    const resetRequest = () => {
        dispatch({
            type: RequestActions.RESET,
        });
    }


    // On retourne :
    // - les données et le status d'exécution contenus dans 'state'
    // - la méthode à utiliser pour lancer la requête
    return [state, fetchRequest, abortRequest, resetRequest];
};
