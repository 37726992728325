import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";





// Fonction permettant de convertir une chaîne composée de 10 chiffres
// en un numéro de téléphone sous la forme : "00-00-00-00-00"
// Le séparateur peut-être modifié en changer la valeur de 'sep'
export const formatPhoneNumber = (phoneNumber, sep="-") => {
    return phoneNumber.slice(0, 2) + sep + phoneNumber.slice(2, 4) + sep + phoneNumber.slice(4, 6) + sep + phoneNumber.slice(6, 8) + sep + phoneNumber.slice(8, 10);
};





//
export const compute_str_date_range = (dateStart, dateEnd, hasStartTime, hasEndTime) => {
    // Mise en forme des dates
    const strDateStart = dateStart && date_fns_format(parseISO(dateStart), 'EEE dd MMMM yyyy', {locale: fr});
    const strDateEnd = dateEnd && date_fns_format(parseISO(dateEnd), 'EEE dd MMMM yyyy', {locale: fr});

    // Mise en forme des heures
    const strTimeStart = dateStart && hasStartTime && date_fns_format(parseISO(dateStart), 'HH:mm', {locale: fr});
    const strTimeEnd = dateEnd && hasEndTime && date_fns_format(parseISO(dateEnd), 'HH:mm', {locale: fr});


    // Calcul de l'intervalle associé aux heures de début et de fin
    // Si 'strTimeStart' ET 'strTimeEnd' sont à 'null' alors 'strTimeHappening' reste à 'null'
    let strTimeHappening = null;
    if(strTimeStart) {
        if(strTimeEnd) {
            strTimeHappening = `de ${strTimeStart} à ${strTimeEnd}`;
        } else {
            strTimeHappening = `à partir de ${strTimeStart}`;
        }
    } else if(strTimeEnd) {
        strTimeHappening = `jusqu'à ${strTimeEnd}`;
    }


    // Calcul de l'intervalle associé aux dates de début et de fin
    let strDateHappening = "";
    if(strDateStart) {
        if(strDateEnd) {
            if(strDateStart === strDateEnd) {
                strDateHappening = `Le ${strDateStart}`;
            } else {
                strDateHappening = `Du ${strDateStart} au ${strDateEnd}`;
            }
        } else {
            strDateHappening = `À partir du ${strDateStart}`;
        }

        if(strTimeHappening) {
            strDateHappening += " - " + strTimeHappening;
        }
    } else {
        strDateHappening = "Date de l'événement non précisé"
    }

    // On retourne la chaîne résultat
    return strDateHappening;
}
