 import React from 'react';

import {
    Box,
    Grid,
    List,
    ListItem,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';


import { ArticlePreviewCard } from "PathArticle/components/ArticlePreviewCard.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentArticleListRoot: style => ({
        ...style.contentArticleListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentArticleListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentArticleListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentArticleListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentArticleListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentArticleListRoot.xl,
        },
    }),

    contentArticleListTitle: style => ({
        ...style.contentArticleListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentArticleListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentArticleListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentArticleListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentArticleListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentArticleListTitle.xl,
        },
    }),

    contentArticleListItem: style => ({
        ...style.contentArticleListItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentArticleListItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentArticleListItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentArticleListItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentArticleListItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentArticleListItem.xl,
        },
    }),

}));



//
// Composant permettant d'afficher une liste d'article, en mode 'preview'
//
export const ContentArticleList = (props) => {

    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentArticleListRoot: {
                    common: {
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentArticleListTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentArticleListItem: {
                    common: {
                        gap: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Actuellement, deux modes d'affichage sont disponbiles :
    // > md : mode 'desktop' (grand écran)
    // <= md : mode 'mobile'
    const isMDUp = useMediaQuery(theme => theme.breakpoints.up('md'));

    return (
        <Box
            className={classes.contentArticleListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentArticleListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.length > 0 ?

                isMDUp ?

                    <Grid
                        container
                        spacing={3}
                    >
                        {data.map((article) => (
                            <Grid
                                item
                                xs={12} sm={6} lg={4}
                                key={article.id}
                            >
                                <ArticlePreviewCard
                                    article={article}
                                />
                            </Grid>
                        ))}
                    </Grid>

                :

                    <List className={classes.root}>
                        {data.map((article) =>
                            <ListItem
                                key={article.id}
                                className={classes.contentArticleListItem}
                                alignItems="flex-start"
                            >
                                <ArticlePreviewCard
                                    article={article}
                                />
                            </ListItem>
                        )}
                    </List>

            :
                <Typography>
                    Aucun article disponible pour le moment
                </Typography>
            }
        </Box>
    );
}
