import React from 'react';

import {
    createContext,
    useCallback,
    useContext,
    useState,
} from 'react';


// Importation du fichier de configuration de la partie WWW
import {
    API_CONTENT_PAGE_GET_FROM_URL_URL,
} from "PathWWW/components/WWWConfig.jsx";





//
// Contexte
// 'data' : 'Object' contenant toutes les données courantes
// 'fetchData' : fonction permettant de demander de nouvelles données au serveur
//
export const DataLoaderContext = createContext({
    data: {},
    fetchData: (url) => {},
});



//
// Création d'un composant permettant de simplifier la mise en place du 'Provider'
// Ce composant permet de créer le 'contextValue' et défini l'interface utilisable
//
export const DataLoaderProvider = (props) => {

    // On regarde si des données sont déjà existantes (issues du serveur)
    // dans le cas du rendu SSR
    let currentData;
    if (__isBrowser__ && window.APP_STATE) {

        // On est côté 'client' et des données ont été transmises par le serveur...
        // Dans ce cas, on récupère les données contenues dans le navigateur
        currentData = window.APP_STATE;
        // et on vide les données du navigateur (on ne s'en sert que lors du premier affichage)
        delete window.APP_STATE;

    } else if (props.staticContext) {

        // On est côté 'serveur' : on récupère les données récupérées précédemment et passées dans le contexte
        currentData = props.staticContext;

    }


    // On sauvegarde les données récupérées précédemment, si existantes
    const [data, setData] = useState(currentData || []);



    // Fonction permettant de demander des données au serveur
    const fetchData = (url) => {

        fetch(
            `${API_CONTENT_PAGE_GET_FROM_URL_URL}?url=${url}`,
            { method: 'GET' }

        ).then((response) => {
            // On récupère un objet de type 'Response'
            // dont on extrait les données au format JSON
            return response.json();

        }).then((data) => {
            // On sauvegarde toutes les données, qui sont déjà au format JSON
            setData(data);
        });

    };



    // Valeur associée au contexte : contient les données et l'interface
    const contextValue = {
        data,
        fetchData: useCallback((url) => fetchData(url), []),
    };

    // Mise en place du provider
    return (
        <DataLoaderContext.Provider value={contextValue}>
            {props.children}
        </DataLoaderContext.Provider>
    );
}



//
// Hook personnalisé permettant d'accéder aux fonctionnalités du 'DataLoaderContext'
// Ce hook peut être appelé n'import où dans le code
//
export const useDataLoader = () => {

    // On récupère les différents éléments liés au contexte...
    const { data, fetchData } = useContext(DataLoaderContext);

    // ... et on les retourne
    return { data, fetchData };
}
