import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant 'NewsSlide'
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useNewsSlideStyles = () => makeStyles((theme) => ({

    newsPreviewCard: style => ({
        ...style.newsPreviewCard.common,

        [theme.breakpoints.only('xs')]: {
            ...style.newsPreviewCard.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.newsPreviewCard.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.newsPreviewCard.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.newsPreviewCard.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.newsPreviewCard.xl,
        },
    }),

    newsPreviewCardActionArea: style => ({
        ...style.newsPreviewCardActionArea.common,

        [theme.breakpoints.only('xs')]: {
            ...style.newsPreviewCardActionArea.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.newsPreviewCardActionArea.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.newsPreviewCardActionArea.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.newsPreviewCardActionArea.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.newsPreviewCardActionArea.xl,
        },
    }),

    newsPreviewCardMedia: style => ({
        ...style.newsPreviewCardMedia.common,

        [theme.breakpoints.only('xs')]: {
            ...style.newsPreviewCardMedia.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.newsPreviewCardMedia.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.newsPreviewCardMedia.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.newsPreviewCardMedia.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.newsPreviewCardMedia.xl,
        },
    }),

    newsPreviewCardContent: style => ({
        ...style.newsPreviewCardContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.newsPreviewCardContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.newsPreviewCardContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.newsPreviewCardContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.newsPreviewCardContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.newsPreviewCardContent.xl,
        },
    }),

    newsPreviewCardContentTitle: style => ({
        ...style.newsPreviewCardContentTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.newsPreviewCardContentTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.newsPreviewCardContentTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.newsPreviewCardContentTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.newsPreviewCardContentTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.newsPreviewCardContentTitle.xl,
        },
    }),

    newsPreviewCardContentSignatureDate: style => ({
        ...style.newsPreviewCardContentSignatureDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.newsPreviewCardContentSignatureDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.newsPreviewCardContentSignatureDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.newsPreviewCardContentSignatureDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.newsPreviewCardContentSignatureDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.newsPreviewCardContentSignatureDate.xl,
        },
    }),

}));



//
// Composant représentant une actualité présentée sous
// forme d'une carte.
//
export const NewsSlide = ({ news, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useNewsSlideStyles()(
        {
            newsPreviewCard: {
                common: {
                    height: '100%',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            newsPreviewCardActionArea: {
                common: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            newsPreviewCardMedia: lodashMerge(
                {
                    common: {
                        width: '100%',
                        height: '60%',
                        objectFit: 'cover',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                (news && news.img_header && news.img_header.style) || {},
            ),

            newsPreviewCardContent: {
                common: {
                    width: '100%',
                    height: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            newsPreviewCardContentTitle: {
                common: {
                    fontSize: '1.2rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            newsPreviewCardContentSignatureDate: {
                common: {
                    paddingTop: theme.spacing(2),
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    opacity: '0.8',
                    marginTop: 'auto',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },
        },
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Mise en forme des dates
    let str_date_publication_start = news.date_publication_start && date_fns_format(parseISO(news.date_publication_start), 'dd/MM/yyyy', {locale: fr});

    return (
        <Card className={classes.newsPreviewCard}>
            <CardActionArea
                className={classes.newsPreviewCardActionArea}
                component={Link}
                to={{ pathname: news.url_detail }}
            >
                {news.img_header.src &&
                    <CardMedia
                        className={classes.newsPreviewCardMedia}
                        component="img"
                        src={news.img_header.src}
                        srcSet={news.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                        title={`${news.img_header.legend} - ${news.img_header.credits}`}
                        alt={news.img_header.alt}
                    />
                }
                <CardContent
                    className={classes.newsPreviewCardContent}
                    component="span"
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.newsPreviewCardContentTitle}
                    >
                        {news.title}
                    </Typography>

                    <Box
                        component="span"
                        className={classes.newsPreviewCardContentSignatureDate}
                    >
                        Publié le {str_date_publication_start} par {news.signature}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
