import React from 'react';

import {
    Avatar,
    Box,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    Link,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import GradeIcon from '@material-ui/icons/Grade';
import PhoneIcon from '@material-ui/icons/Phone';

import TwitterIcon from '@material-ui/icons/Twitter';
import InstagramIcon from '@material-ui/icons/Instagram';
import PinterestIcon from '@material-ui/icons/Pinterest';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import LanguageIcon from '@material-ui/icons/Language';

import { formatPhoneNumber } from "PathCore/tools/utils.jsx";

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentNPOrganisationDetailRoot: style => ({
        ...style.contentNPOrganisationDetailRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNPOrganisationDetailRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNPOrganisationDetailRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNPOrganisationDetailRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNPOrganisationDetailRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNPOrganisationDetailRoot.xl,
        },
    }),

    contentNPOrganisationDetailTitle: style => ({
        ...style.contentNPOrganisationDetailTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNPOrganisationDetailTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNPOrganisationDetailTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNPOrganisationDetailTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNPOrganisationDetailTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNPOrganisationDetailTitle.xl,
        },
    }),

    contentNPOrganisationDetailCard: {
        height: '100%',
    },
    contentNPOrganisationDetailCardContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    contentNPOrganisationDetailCardContentBoxHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    contentNPOrganisationDetailCardContentAvatar: {
        marginRight: theme.spacing(2),
        width: '10vh',
        height: '10vh',

        '& img': {
            objectFit: 'contain',
        }
    },
    contentNPOrganisationDetailCardContentBoxTitle: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentNPOrganisationDetailCardName: {
        fontWeight: 'bold',
    },
    contentNPOrganisationDetailCardKind: {
        fontStyle: 'italic',
        fontSize: 'small',
        marginTop: theme.spacing(1),
    },
    contentNPOrganisationDetailCardContentBoxChips: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
    },
    contentNPOrganisationDetailCardContentChipTag: {
        marginRight: theme.spacing(1),
    },
    contentNPOrganisationDetailCardContentDivider: {
        marginTop: theme.spacing(2),
    },
    contentNPOrganisationDetailCardContentTitle: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
        fontSize: 'large',
        textDecoration: 'underline',
    },
    contentNPOrganisationDetailCardContentContact: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(1),
    },
    contentNPOrganisationDetailCardContentContactName:{
        fontWeight: 'bold',
    },
    contentNPOrganisationDetailCardContentContactGrade: {
        fontStyle: 'italic',
    },
    contentNPOrganisationDetailCardContentContactItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
    contentNPOrganisationDetailCardContentLinkItem: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: theme.spacing(1),
    },
}));



//
// Composant permettant d'afficher le détail d'une association
//
export const ContentNPOrganisationDetail = (props) => {

    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentNPOrganisationDetailRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentNPOrganisationDetailTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentNPOrganisationDetailRoot}
        >
            {title &&
                <Typography
                    className={classes.contentNPOrganisationDetailTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Card component="article" className={classes.contentNPOrganisationDetailCard}>
                <CardContent className={classes.contentNPOrganisationDetailCardContent}>
                    <Box className={classes.contentNPOrganisationDetailCardContentBoxHeader}>

                        <Avatar
                            className={classes.contentNPOrganisationDetailCardContentAvatar}
                            variant="rounded"
                            alt={data.name}
                            src={data.logo_resized_src || ""}
                            srcSet={data.logo_resized_srcset || ""}
                        />

                        <Box className={classes.contentNPOrganisationDetailCardContentBoxTitle}>
                            <Typography component="h2" className={classes.contentNPOrganisationDetailCardName}>
                                {data.name}
                            </Typography>

                            <Typography component="span" variant="body1"  className={classes.contentNPOrganisationDetailCardKind}>
                                {data.kind_label}
                            </Typography>

                            <Box className={classes.contentNPOrganisationDetailCardContentBoxChips}>
                                {data.tags.length > 0 && data.tags.split(";").map((tag, index) =>
                                    <Chip
                                        key={index}
                                        className={classes.contentNPOrganisationDetailCardContentChipTag}
                                        label={tag}
                                        variant="outlined"
                                    />
                                )}
                            </Box>

                        </Box>

                    </Box>

                    <Divider className={classes.contentNPOrganisationDetailCardContentDivider} />

                    <Box>
                        <Box
                            dangerouslySetInnerHTML={{ __html: data.description }}
                        />

                        <Typography component="h3" className={classes.contentNPOrganisationDetailCardContentTitle}>
                            Contacts
                        </Typography>

                        {data.contacts && data.contacts.length > 0 ?
                            <Grid container spacing={3}>
                                {data.contacts.map((contact, index) =>
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Box className={classes.contentNPOrganisationDetailCardContentContact}>
                                            <Typography className={classes.contentNPOrganisationDetailCardContentContactName}>{contact.name}</Typography>
                                            {contact.grade && <Typography component="span" className={classes.contentNPOrganisationDetailCardContentContactGrade}>{contact.grade}</Typography>}
                                            {contact.phone && <Box className={classes.contentNPOrganisationDetailCardContentContactItem}><PhoneIcon color="action" /><Typography component="span">{formatPhoneNumber(contact.phone, ".")}</Typography></Box>}
                                            {contact.email && <Box className={classes.contentNPOrganisationDetailCardContentContactItem}><EmailIcon color="action" /><Typography component="span">{contact.email}</Typography></Box>}
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        :
                            <Typography component="span">Aucun contact renseigné</Typography>
                        }

                        <Typography component="h3" className={classes.contentNPOrganisationDetailCardContentTitle}>
                            Liens
                        </Typography>

                        {data.links && data.links.length > 0 ?
                            <Grid container spacing={3}>
                                {data.links.map((link, index) =>
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Box className={classes.contentNPOrganisationDetailCardContentLinkItem}>
                                            {link.kind === "facebook" && <FacebookIcon color="action" />}
                                            {link.kind === "twitter" && <TwitterIcon color="action" />}
                                            {link.kind === "instagram" && <InstagramIcon color="action" />}
                                            {link.kind === "pinterest" && <PinterestIcon color="action" />}
                                            {link.kind === "linkedin" && <LinkedInIcon color="action" />}
                                            {link.kind === "website" && <LanguageIcon color="action" />}
                                            {link.kind === "other" && `${link.label} : `}
                                            <Link
                                                href={link.link}
                                                target="_blank"
                                                rel="noopener"
                                            >
                                                {link.label}
                                            </Link>
                                        </Box>
                                    </Grid>
                                )}
                            </Grid>
                        :
                            <Typography component="span">Aucun lien renseigné</Typography>
                        }
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
}
