// Configuration générale de l'intranet

export const ROOT_URL = "/intranet/";
export const LOGIN_PAGE_URL = ROOT_URL + "login/";
export const MAIN_PAGE_URL = ROOT_URL + "tableau-de-bord/";
export const DIRECT_LINK_URL = ROOT_URL + "acces-rapides/";
export const CONTACT_URL = ROOT_URL + "contacts/";
export const ARTICLE_URL = ROOT_URL + "articles/";
export const HAPPENING_URL = ROOT_URL + "evenements/";
export const NEWS_URL = ROOT_URL + "actualites/";
export const NEWSLETTER_URL = ROOT_URL + "newsletters/";
export const NPORGANISATION_URL = ROOT_URL + "associations/";
export const ORGANISATION_URL = ROOT_URL + "entreprises/";
export const RESOURCE_DOCUMENT_URL = ROOT_URL + "ressources/documents/";
export const RESOURCE_PHOTO_URL = ROOT_URL + "ressources/photos/";
export const RESOURCE_PHOTO_GALLERY_URL = ROOT_URL + "ressources/galeries-photos/";

export const API_DASHBOARD_FULL_URL = "/intranet/api/dashboard-infos/";
export const API_SECTION_FULL_URL = "/api/section/";
export const API_SECTION_SELECT_URL = "/api/section/select/";
export const API_SECTION_DIRECT_LINK_POSITIONS_URL = "/api/section-direct-link-positions/";
export const API_SECTION_CONTACT_POSITIONS_URL = "/api/section-contact-positions/";
export const API_DIRECT_LINK_FULL_URL = "/api/direct-link/";
export const API_DIRECT_LINK_KIND_FULL_URL = "/api/direct-link-kind/";
export const API_CONTACT_FULL_URL = "/api/contact/";
export const API_ARTICLE_FULL_URL = "/api/article/";
export const API_ARTICLE_TABLE_URL = "/api/article/table/";
export const API_ARTICLE_NEWSLETTER_URL = "/api/article/newsletter/";
export const API_ARTICLE_NEWSLETTER_SUMMARY_URL = "/api/article/newsletter-summary/";
export const API_ARTICLE_REORDER_URL = "/api/article/reorder/";
export const API_HAPPENING_FULL_URL = "/api/happening/";
export const API_HAPPENING_TABLE_URL = "/api/happening/table/";
export const API_HAPPENING_NEWSLETTER_URL = "/api/happening/newsletter/";
export const API_HAPPENING_NEWSLETTER_SUMMARY_URL = "/api/happening/newsletter-summary/";
export const API_NEWS_FULL_URL = "/api/news/";
export const API_NEWS_TABLE_URL = "/api/news/table/";
export const API_NEWS_NEWSLETTER_URL = "/api/news/newsletter/";
export const API_NEWS_NEWSLETTER_SUMMARY_URL = "/api/news/newsletter-summary/";
export const API_NEWSLETTER_FULL_URL = "/api/newsletter/";
export const API_NEWSLETTER_TABLE_URL = "/api/newsletter/table/";
export const API_NEWSLETTER_ADD_USER_URL = "/api/newsletter/add-user/";
export const API_NEWSLETTER_GENERATE_URL = "/api/newsletter/generate/";
export const API_NPORGANISATION_FULL_URL = "/api/nporganisation/";
export const API_NPORGANISATION_TABLE_URL = "/api/nporganisation/table/";
export const API_NPORGANISATION_KIND_SELECT_URL = "/api/nporganisation-kind/select/";
export const API_ORGANISATION_FULL_URL = "/api/organisation/";
export const API_ORGANISATION_TABLE_URL = "/api/organisation/table/";
export const API_ORGANISATION_KIND_SELECT_URL = "/api/organisation-kind/select/";
export const API_RESOURCE_DOCUMENT_FULL_URL = "/api/resource/document/";
export const API_RESOURCE_DOCUMENT_TABLE_URL = "/api/resource/document/table/";
export const API_RESOURCE_DOCUMENT_KIND_FULL_URL = "/api/resource/document-kind/";
export const API_RESOURCE_DOCUMENT_KIND_LIST_WITH_NB_DOCS_URL = "/api/resource/document-kind/listWithNbDocuments/";
export const API_RESOURCE_PHOTO_FULL_URL = "/api/resource/photo/";
export const API_RESOURCE_PHOTO_TABLE_URL = "/api/resource/photo/table/";
export const API_RESOURCE_PHOTO_GALLERY_FULL_URL = "/api/resource/photo-gallery/";
export const API_RESOURCE_PHOTO_GALLERY_TABLE_URL = "/api/resource/photo-gallery/table/";
export const API_WEBSITE_FULL_URL = "/api/website/";
export const API_VENDORS_GA_GENERAL_STATS_URL = "/api/vendors/google-analytics-stats/get-general-stats/";
export const API_VENDORS_GA_ACTIVE_USERS_PER_DAY_STATS_URL = "/api/vendors/google-analytics-stats/get-active-users-per-day-stats/";
export const API_VENDORS_GA_SCREEN_PAGE_VIEWS_PER_DAY_STATS_URL = "/api/vendors/google-analytics-stats/get-screen-page-views-per-day-stats/";
export const API_VENDORS_GA_SEARCH_CLICKS_AND_IMPRESSIONS_PER_DAY_STATS_URL = "/api/vendors/google-analytics-stats/get-search-clicks-and-impressions-per-day-stats/";
export const API_VENDORS_GA_SCREEN_PAGE_VIEWS_PER_PATH_STATS_URL = "/api/vendors/google-analytics-stats/get-screen-page-views-per-path-stats/";



/////////////////////////////////////////////////////////////////////
//                                                                 //
//     Constantes utilisées pour la génération des newsletters     //
//                                                                 //
/////////////////////////////////////////////////////////////////////

// Constantes utilisées pour distinguer les différents types d'éléments
// ATTENTION : ces constantes doivent être les mêmes côté serveur
export const NEWSLETTER_DATA_KIND_ARTICLE = "article";
export const NEWSLETTER_DATA_KIND_NEWS = "news";
export const NEWSLETTER_DATA_KIND_HAPPENING = "happening";


// Énumération utilisée pour récupérer le label associé au type de donnée 'DATA_KIND_XXX'
// (= article, actulité ou événement)
export const NEWSLETTER_DATA_KIND_LABEL = Object.freeze({
    [NEWSLETTER_DATA_KIND_ARTICLE]: "article",
    [NEWSLETTER_DATA_KIND_NEWS]: "actualité",
    [NEWSLETTER_DATA_KIND_HAPPENING]: "événement",
});
