import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';


import { OSMMap } from "PathCore/tools/OSMMap.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentMapRoot: style => ({
        ...style.contentMapRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentMapRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentMapRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentMapRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentMapRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentMapRoot.xl,
        },
    }),

    contentMapTitle: style => ({
        ...style.contentMapTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentMapTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentMapTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentMapTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentMapTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentMapTitle.xl,
        },
    }),

    contentMapContent: style => ({
        ...style.contentMapContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentMapContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentMapContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentMapContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentMapContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentMapContent.xl,
        },
    }),

    contentMapOSMMap: style => ({
        ...style.contentMapOSMMap.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentMapOSMMap.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentMapOSMMap.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentMapOSMMap.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentMapOSMMap.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentMapOSMMap.xl,
        },
    }),

    contentMapDescription: style => ({
        ...style.contentMapDescription.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentMapDescription.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentMapDescription.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentMapDescription.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentMapDescription.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentMapDescription.xl,
        },
    }),

}));


//
// Composant permettant d'afficher une cart OSM, avec éventuellement un texte à côté
//
export const ContentMap = (props) => {


    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentMapRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentMapTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentMapContent: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentMapOSMMap: {
                    common: {
                        flexBasis: '0%',
                        flexGrow: '1',
                        flexShrink: '1',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentMapDescription: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        flexBasis: '0%',
                        flexGrow: '1',
                        flexShrink: '1',
                        textAlign: 'justify',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentMapRoot}
        >
            {title &&
                <Typography
                    className={classes.contentMapTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Box
                className={classes.contentMapContent}
            >
                {data.city_gps_coordinates &&
                    <Box
                        className={classes.contentMapOSMMap}
                    >
                        <OSMMap
                            center={params.center || data.city_gps_coordinates}
                            zoom={params.zoom || "auto"}
                            markers={params.markers || null}
                            outline={params.outline || data.city_outline}
                            gestureHandling={params.gestureHandling || true}
                        />
                    </Box>
                }

                {data.description &&
                    <Box
                        className={classes.contentMapDescription}
                        dangerouslySetInnerHTML={{ __html: data.description }}
                    />
                }
            </Box>
        </Box>
    );
}
