import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentNPOrganisationListRoot: style => ({
        ...style.contentNPOrganisationListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNPOrganisationListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNPOrganisationListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNPOrganisationListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNPOrganisationListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNPOrganisationListRoot.xl,
        },
    }),

    contentNPOrganisationListTitle: style => ({
        ...style.contentNPOrganisationListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNPOrganisationListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNPOrganisationListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNPOrganisationListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNPOrganisationListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNPOrganisationListTitle.xl,
        },
    }),

    contentNPOrganisationListGridContainer: style => ({
        ...style.contentNPOrganisationListGridContainer.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNPOrganisationListGridContainer.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNPOrganisationListGridContainer.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNPOrganisationListGridContainer.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNPOrganisationListGridContainer.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNPOrganisationListGridContainer.xl,
        },
    }),

    contentNPOrganisationListGridItem: style => ({
        ...style.contentNPOrganisationListGridItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNPOrganisationListGridItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNPOrganisationListGridItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNPOrganisationListGridItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNPOrganisationListGridItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNPOrganisationListGridItem.xl,
        },
    }),

    contentNPOrganisationListCard: {
        height: '30vh',
    },
    contentNPOrganisationListCardActionArea: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    contentNPOrganisationListCardContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    contentNPOrganisationListCardContentBoxHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    contentNPOrganisationListCardContentAvatar: {
        marginRight: theme.spacing(2),
        width: '10vh',
        height: '10vh',

        '& img': {
            objectFit: 'contain',
        }
    },
    contentNPOrganisationListCardContentBoxTitle: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentNPOrganisationListCardName: {
        fontWeight: 'bold',
    },
    contentNPOrganisationListCardKind: {
        fontStyle: 'italic',
        fontSize: 'small',
        marginTop: theme.spacing(1),
    },
    contentNPOrganisationListCardContentBoxChips: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
    },
    contentNPOrganisationListCardContentChipTag: {
        marginRight: theme.spacing(1),
    },
    contentNPOrganisationListCardContentDivider: {
        marginTop: theme.spacing(2),
    },
    contentNPOrganisationListCardContentDescription: {
        height: '100%',
        overflow: 'hidden',
        maskImage: 'linear-gradient(black 70%, transparent 100%)',
    },
}));





//
// Composant permettant d'afficher l'annuaire des associations (en mode 'preview')
//
export const ContentNPOrganisationList = (props) => {

    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentNPOrganisationListRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNPOrganisationListTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNPOrganisationListGridContainer: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNPOrganisationListGridItem: {
                    common: {},
                    xs: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    sm: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    md: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Rien à faire
    return (
        <Box
            className={classes.contentNPOrganisationListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentNPOrganisationListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.length > 0 ?
                <Grid
                    className={classes.contentNPOrganisationListGridContainer}
                    container
                    spacing={3}
                >
                    {data.map((nporganisation) => (
                        <Grid
                            className={classes.contentNPOrganisationListGridItem}
                            item
                            xs={12} sm={6} lg={4}
                            key={nporganisation.id}
                        >
                            <Card component="article" className={classes.contentNPOrganisationListCard}>
                                <CardActionArea
                                    className={classes.contentNPOrganisationListCardActionArea}
                                    component={Link}
                                    to={{ pathname: `/annuaire-des-associations/${nporganisation.id}-${nporganisation.name_slug}/` }}
                                >
                                    <CardContent className={classes.contentNPOrganisationListCardContent}>
                                        <Box className={classes.contentNPOrganisationListCardContentBoxHeader}>

                                            <Avatar
                                                className={classes.contentNPOrganisationListCardContentAvatar}
                                                variant="rounded"
                                                alt={nporganisation.name}
                                                src={nporganisation.logo_resized_src || ""}
                                                srcSet={nporganisation.logo_resized_srcset || ""}
                                            />

                                            <Box className={classes.contentNPOrganisationListCardContentBoxTitle}>
                                                <Typography component="h2" className={classes.contentNPOrganisationListCardName}>
                                                    {nporganisation.name}
                                                </Typography>

                                                <Typography component="span" variant="body1"  className={classes.contentNPOrganisationListCardKind}>
                                                    {nporganisation.kind_label}
                                                </Typography>

                                                <Box className={classes.contentNPOrganisationListCardContentBoxChips}>
                                                    {nporganisation.tags.length > 0 && nporganisation.tags.split(";").map((tag, index) =>
                                                        <Chip
                                                            key={index}
                                                            className={classes.contentNPOrganisationListCardContentChipTag}
                                                            label={tag}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                </Box>

                                            </Box>

                                        </Box>

                                        <Divider className={classes.contentNPOrganisationListCardContentDivider} />

                                        <Box
                                            className={classes.contentNPOrganisationListCardContentDescription}
                                            dangerouslySetInnerHTML={{ __html: nporganisation.description }}
                                        />
                                    </CardContent>

                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            :
                <Typography>
                    Aucune association disponible pour le moment
                </Typography>
            }
        </Box>
    );
}
