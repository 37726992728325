import React from 'react';

import {
    Box,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentNewsDetailTitle: style => ({
        ...style.contentNewsDetailTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsDetailTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsDetailTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsDetailTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsDetailTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsDetailTitle.xl,
        },
    }),

    contentNewsDetailRoot: style => ({
        ...style.contentNewsDetailRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsDetailRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsDetailRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsDetailRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsDetailRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsDetailRoot.xl,
        },
    }),

    contentNewsDetailHeaderImg: style => ({
        ...style.contentNewsDetailHeaderImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsDetailHeaderImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsDetailHeaderImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsDetailHeaderImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsDetailHeaderImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsDetailHeaderImg.xl,
        },
    }),

    contentNewsDetailContent: style => ({
        ...style.contentNewsDetailContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsDetailContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsDetailContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsDetailContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsDetailContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsDetailContent.xl,
        },
    }),

    contentNewsDetailSignatureDate: style => ({
        ...style.contentNewsDetailSignatureDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsDetailSignatureDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsDetailSignatureDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsDetailSignatureDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsDetailSignatureDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsDetailSignatureDate.xl,
        },
    }),

}));



//
// Composant permettant d'afficher le détail d'une actualité
//
export const ContentNewsDetail = ({ title, params, style, data }) => {

    // Actuellement, deux modes d'affichage sont disponbiles :
    // > md : mode 'desktop' (grand écran)
    // <= md : mode 'mobile'
    const isMDUp = useMediaQuery(theme => theme.breakpoints.up('md'));


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentNewsDetailTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentNewsDetailRoot: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                        borderRadius: '0px',
                        color: 'rgba(0, 0, 0, 0.87)',
                        transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        backgroundColor: '#fff',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        borderRadius: '4px',
                    },
                    lg: {
                        borderRadius: '4px',
                    },
                    xl: {
                        borderRadius: '4px',
                    },
                },
                contentNewsDetailHeaderImg: lodashMerge(
                    {
                        common: {
                            width: '100%',
                            height: '100%',
                            maxHeight: '50vh',
                            objectFit: 'cover',
                        },
                        xs: {},
                        sm: {},
                        md: {
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                        },
                        lg: {
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                        },
                        xl: {
                            borderTopLeftRadius: '4px',
                            borderTopRightRadius: '4px',
                        },
                    },
                    (data && data.img_header && data.img_header.style) || {},
                ),
                contentNewsDetailContent: {
                    common: {
                        padding: '1.5rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentNewsDetailSignatureDate: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        marginRight: '1.5rem',
                        marginBottom: '1.5rem',
                        fontSize: '0.9rem',
                        fontStyle: 'italic',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            {data.title &&
                <Typography
                    className={classes.contentNewsDetailTitle}
                    component="h1"
                    variant="h1"
                >
                    {data.title}
                </Typography>
            }

            <Box
                className={classes.contentNewsDetailRoot}
            >
                {data.img_header && data.img_header.src &&
                    <img
                        className={classes.contentNewsDetailHeaderImg}
                        src={data.img_header.src}
                        srcSet={data.img_header.srcset}
                        sizes={isMDUp ? "75vw" : "100vw"}
                        title={`${data.img_header.legend} - ${data.img_header.credits}`}
                        alt={data.img_header.alt}
                    />
                }

                <Box
                    className={classes.contentNewsDetailContent}
                    dangerouslySetInnerHTML={{ __html: data.content }}
                />

                <Box className={classes.contentNewsDetailSignatureDate}>
                    <Box componant="span">
                        {data.signature}
                    </Box>
                    <Box componant="span">
                        Publié le {data.date_publication_start && date_fns_format(parseISO(data.date_publication_start), 'EEEE dd MMMM yyyy', {locale: fr})}
                    </Box>
                </Box>
            </Box>
        </>
    );
}
