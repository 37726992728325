import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardHeader,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    saintPreviewCard: {
        height: '100%',
    },
    saintPreviewCardActionArea: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    saintPreviewCardHeader: {
        width: '100%',
        alignSelf: 'flex-start',
        padding: theme.spacing(2),
    },
    saintPreviewCardContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        paddingTop: '0px',
        flexGrow: '1',
    },
    saintPreviewCardContentBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    saintPreviewCardContentTitle: {
        marginTop: '0px',
    },
    saintPreviewCardContentName: {
        marginTop: 'auto',
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    saintPreviewCardContentDescription: {
        marginBottom: 'auto',
    },
    saintPreviewCardContentCopyright: {
        marginTop: 'auto',
        fontSize: '0.8rem',
        fontStyle: 'italic',
        alignSelf: 'flex-end',
        marginRight: theme.spacing(4),
    },
}));





//
// Composant représentant l'éphéméride du jour présentée sous
// forme d'une carte.
//
export const SaintSlide = (props) => {


    // On récupère les propriétés...
    const { saint } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.saintPreviewCard}>
            <CardActionArea
                className={classes.saintPreviewCardActionArea}
                component={Link}
                to={{ pathname: saint.link }}
                rel="noopener noreferrer"
                target="_blank"
            >
                <CardHeader
                    className={classes.saintPreviewCardHeader}
                    title="Éphéméride"
                />
                <CardContent
                    className={classes.saintPreviewCardContent}
                >
                    <Box className={classes.saintPreviewCardContentBox}>
                        <Typography className={classes.saintPreviewCardContentTitle}>Aujourd'hui, nous fêtons les</Typography>
                        <Typography className={classes.saintPreviewCardContentName}>{saint.name}</Typography>
                        <Box
                            className={classes.saintPreviewCardContentDescription}
                            dangerouslySetInnerHTML={{ __html: saint.description }}
                        />
                        <Typography className={classes.saintPreviewCardContentCopyright}>{saint.copyright}</Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
