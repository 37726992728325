import React from 'react';


import { ContentLayout } from "PathContent/components/ContentLayout.jsx";
import { ContentArticleList } from "PathArticle/components/ContentArticleList.jsx";
import { ContentArticleDetail } from "PathArticle/components/ContentArticleDetail.jsx";
import { ContentHappeningDetail } from "PathHappening/components/ContentHappeningDetail.jsx";
import { ContentNewsList } from "PathNews/components/ContentNewsList.jsx";
import { ContentNewsDetail } from "PathNews/components/ContentNewsDetail.jsx";
import { ContentNPOrganisationList } from "PathNPOrganisation/components/ContentNPOrganisationList.jsx";
import { ContentNPOrganisationDetail } from "PathNPOrganisation/components/ContentNPOrganisationDetail.jsx";
import { ContentOrganisationList } from "PathOrganisation/components/ContentOrganisationList.jsx";
import { ContentOrganisationDetail } from "PathOrganisation/components/ContentOrganisationDetail.jsx";
import { ContentOrganisationChart } from "PathOrganisationChart/components/ContentOrganisationChart.jsx";
import { ContentResourceDocumentList } from "PathResources/components/ContentResourceDocumentList.jsx";
import { ContentResourcePhotoDetail } from "PathResources/components/ContentResourcePhotoDetail.jsx";
import { ContentResourcePhotoGallery } from "PathResources/components/ContentResourcePhotoGallery.jsx";
import { ContentStatic } from "PathContent/components/ContentStatic.jsx";
import { ContentDirectLink } from "PathCore/components/ContentDirectLink.jsx";
import { ContentContact } from "PathCore/components/ContentContact.jsx";
import { ContentMap } from "PathContent/components/ContentMap.jsx";
import { ContentCalendar } from "PathContent/components/ContentCalendar.jsx";
import { ContentSlider } from "PathContent/components/ContentSlider.jsx";
import { ContentSearch } from "PathContent/components/ContentSearch.jsx";
import { ContentResourceDoc } from "PathContent/components/ContentResourceDoc.jsx";
import { ContentContactForm } from "PathContent/components/ContentContactForm.jsx";
import { ContentError500 } from "PathContent/components/ContentError500.jsx";




// Table permettant de déterminer les composants 'react' à utiliser, en
// fonction du type envoyé par 'django'
const COMPONENT_MAPPING = {
    "content_layout": ContentLayout,
    "content_static": ContentStatic,
    "content_direct_link": ContentDirectLink,
    "content_contact": ContentContact,
    "content_search": ContentSearch,
    "content_resource_doc": ContentResourceDoc,
    "content_map": ContentMap,
    "content_calendar": ContentCalendar,
    "content_organisationchart": ContentOrganisationChart,
    "content_slider": ContentSlider,
    "content_contact_form": ContentContactForm,
    "content_article_list": ContentArticleList,
    "content_article_detail": ContentArticleDetail,
    "content_happening_detail": ContentHappeningDetail,
    "content_news_list": ContentNewsList,
    "content_news_detail": ContentNewsDetail,
    "content_nporganisation_list": ContentNPOrganisationList,
    "content_nporganisation_detail": ContentNPOrganisationDetail,
    "content_organisation_list": ContentOrganisationList,
    "content_organisation_detail": ContentOrganisationDetail,
    "content_resource_document_list": ContentResourceDocumentList,
    "content_resource_photo_detail": ContentResourcePhotoDetail,
    "content_resource_photo_gallery": ContentResourcePhotoGallery,
};



//
// Composant permettant de choisir le composant à utiliser pour
// afficher le contenu, en fonction de son type
//
export const ContentFactory = ({ content, ...props }) => {


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On récupère le composant 'react' à utiliser en fonction du type 'content.content_type'
    // ou on retourne une erreur 500 si le composant n'a pas été trouvé dans la table
    const Component = COMPONENT_MAPPING[content.content_type] || ContentError500;

    return (
        <Component
            title={content.content_title}
            params={content.content_params}
            style={content.content_style}
            data={content.content_data}
            {...props}
        />
    );
}
