import React from 'react';

import {
    useMemo,
} from 'react';

import {
    Box,
    Typography,
    useMediaQuery,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';


import {
    Slideshow,
    SlideshowContent,
    SlideshowSlide,
} from "PathCore/tools/Slideshow.jsx";

import { HappeningSlide } from "PathHappening/components/HappeningSlide.jsx";
import { NewsSlide } from "PathNews/components/NewsSlide.jsx";
import { MeteoSlide } from "PathCore/components/MeteoSlide.jsx";
import { SaintSlide } from "PathCore/components/SaintSlide.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentSliderRoot: style => ({
        ...style.contentSliderRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderRoot.xl,
        },
    }),

    contentSliderTitle: style => ({
        ...style.contentSliderTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderTitle.xl,
        },
    }),

    contentSliderSlideshow: style => ({
        ...style.contentSliderSlideshow.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderSlideshow.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderSlideshow.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderSlideshow.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderSlideshow.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderSlideshow.xl,
        },
    }),
    contentSliderSlide: style => ({
        ...style.contentSliderSlide.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderSlide.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderSlide.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderSlide.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderSlide.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderSlide.xl,
        },
    }),
    contentSliderBoxOne: style => ({
        ...style.contentSliderBoxOne.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSliderBoxOne.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSliderBoxOne.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSliderBoxOne.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSliderBoxOne.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSliderBoxOne.xl,
        },
    }),

}));



//
// Composant permettant d'afficher un slider
// Le slider est composé de carte contenant différentes informations
//
export const ContentSlider = ({ title, params, style, data, ...props }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Si on est côté client (pour le rendu), alors on détermine le mode d'affichage actuel
    // ATTENTION : il faut appeler tous les 'useMediaQuery' à la suite sans faire des
    // 'if / else if' sinon problème car il n'y a pas le même nombre de 'hook' appelés
    // lors des différents rendus
    const isXS = __isBrowser__ && useMediaQuery(theme => theme.breakpoints.only('xs'));
    const isSM = __isBrowser__ && useMediaQuery(theme => theme.breakpoints.only('sm'));
    const isMD = __isBrowser__ && useMediaQuery(theme => theme.breakpoints.only('md'));
    const isLG = __isBrowser__ && useMediaQuery(theme => theme.breakpoints.only('lg'));
    const isXL = __isBrowser__ && useMediaQuery(theme => theme.breakpoints.only('xl'));

    // On détermine le mode
    // Par défaut, on considère que l'on est en mode 'xs' (rendu côté serveur + rendu hydratation)
    const mode = (isSM && "sm") || (isMD && "md") || (isLG && "lg") || (isXL && "xl") || "xs";


    // On récupère le nombre de slides à afficher en fonction du mode calculé précédemment
    // Si pas d'information passée dans les paramètres alors par défaut, on affiche qu'un seul slide
    const nbVisibleSlides = params.nbVisibleSlides && params.nbVisibleSlides[mode] || 1;



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentSliderRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderSlideshow: {
                    common: {
                        display: 'flex',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderSlide: {
                    common: {
                        height: '15rem',
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        marginBottom: '5px',
                    },
                    xs: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    sm: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentSliderBoxOne: {
                    common: {
                        height: '15rem',
                        marginLeft: '20%',
                        marginRight: '20%',
                        marginBottom: '5px',
                    },
                    xs: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    sm: {
                        marginLeft: '0px',
                        marginRight: '0px',
                    },
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Méthode permettant de générer un 'slide' en fonction du type d'informations passées
    const generateSlide = (item) => {
        switch(item.data_kind) {
            case "news":
                return <NewsSlide news={item} />;

            case "happening":
                return <HappeningSlide happening={item} />;

            case "meteo":
                return <MeteoSlide meteo={item} />;

            case "saint":
                return <SaintSlide saint={item} />;

            default:
                return null;
        }
    }


    // Dans le cas où il n'y a pas d'informations dans le slider alors on ne l'affiche pas
    if(data.length === 0) { return null; }

    return (
        <Box
            className={classes.contentSliderRoot}
        >
            {title &&
                <Typography
                    className={classes.contentSliderTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.length === 1 &&
                <Box className={classes.contentSliderBoxOne}>
                    {generateSlide(data[0])}
                </Box>
            }

    	    {data.length >= 2 &&
                useMemo(() => (
                    <Slideshow
                        className={classes.contentSliderSlideshow}
                        name="idSlideshow"
                        nbVisibleSlides={nbVisibleSlides}
                        firstSlide={0}
                        autoPlay={false}
                        transitionDuration={1}
                        overlayArrows={false}
                    >
                        <SlideshowContent>
                            {data.map((item, index) => (
                                <SlideshowSlide
                                    key={index}
                                    className={classes.contentSliderSlide}
                                >
                                    {generateSlide(item)}
                                </SlideshowSlide>
                            ))}
                        </SlideshowContent>
                    </Slideshow>
                ), [
                    data,
                    nbVisibleSlides,
                ])
            }
        </Box>
    );
}
