import React from 'react';

import {
    useState,
} from 'react';

import {
  useLocation,
} from "react-router-dom";

import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';


// Hook permettant de gérer les cookies
import { useCookie } from 'PathCore/hooks/useCookie.jsx';





// Durée de validité par défaut du cookie RGPD : 1 an
const COOKIE_DEFAULT_DURATION = 60*60*24*365;



//
// Boite de dialogue permettant de configurer la gestion des cookies
//
export const CookieDialog = (props) => {



    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook utilisé pour récupérer l'URL courante (pour l'envoyer à Google Analytics si nécessaire)
    let location = useLocation();


    // Hook utilisé pour gérer les cookies
    const [getCookieRGPD, setCookieRGPD] = useCookie("rgpd");


    // Variable permettant de contrôler l'ouverture ou la fermeture
    // de la boîte de dialogue de gestion des cookies
    // Par défaut, la boîte de dialogue est fermée si un cookie 'rgpd' est présent
    // sinon elle est ouverte. Il est également possible de forcer l'ouverture via
    // la propriété 'open'
    const [open, setOpen] = useState(props.open || !Boolean(getCookieRGPD()));


    // Variable permettant de gérer les cases à cocher associées aux différents types de cookies
    const [cookieStates, setCookieStates] = useState(
        getCookieRGPD() ||
        {
            obligatoire: true,
            preferences: true,
            statistiques: true,
            marketing: false,
        }
    );



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // L'utilisateur a fermé la boite de dialogue sans valider ou refuser
    // ('clickaway', 'echap'...)
    const handleClose = (event) => {

        // Si la configuration n'était pas présente...
        if(!getCookieRGPD()) {
            // ... alors par défaut on refuse tous les types (sauf l'obligatoire)
            setCookieRGPD(
                {
                    obligatoire: true,
                    preferences: false,
                    statistiques: false,
                    marketing: false,
                },
                COOKIE_DEFAULT_DURATION,
            );
        } else {
            // Si les cookies étaient déjà configurés...
            if(cookieStates.statistiques) {
                // ... et que le cookie 'statistiques' est à 'true' alors...
                // TODO : comment ne prévenir GA via le Tag Mangaer que dans ce cas ?
            }
        }

        // On ferme la boite de dialogue
        setOpen(false);
    }


    // Callback appelée lorsque l'utilisateur refuse tous les cookies
    const handleDiscard = (event) => {

        // On refuse tous les types (sauf l'obligatoire)
        setCookieRGPD(
            {
                obligatoire: true,
                preferences: false,
                statistiques: false,
                marketing: false,
            },
            COOKIE_DEFAULT_DURATION,
        );

        // On ferme la boite de dialogue
        setOpen(false);
    }


    // Callback appelée lorsque l'utilisateur valide ses choix
    const handleValid = (event) => {

        // On sauvegarde sa sélection
        setCookieRGPD(
            cookieStates,
            COOKIE_DEFAULT_DURATION,
        );

        // Si le cookie 'statistiques' est à 'true'...
        if(cookieStates.statistiques) {
            // TODO : comment ne prévenir GA via le Tag Mangaer que dans ce cas ?
        }

        // On ferme la boite de dialogue
        setOpen(false);
    }


    // Callback appelée lorsque l'utilisateur a cliqué sur l'une des cases à cocher (ou sur le texte associé)
    // La case à cocher sélectionnée est contenue dans 'value'
    const handleToggle = (event, value) => {
        // On inverse la valeur de la case à cocher
        setCookieStates({
            ...cookieStates,
            [value]: !cookieStates[value]
        });
    }



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Gestion des cookies</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Ce site utilise des cookies pour vous offrir la meilleure expérience utilisateur possible. Si vous le souhaitez, vous pouvez désactiver certains d'entre eux.
                </DialogContentText>

                <List>
                    <ListItem key="obligatoire" role={undefined} dense button>
                        <ListItemIcon>
                            <Checkbox
                                disabled
                                edge="start"
                                checked={cookieStates.obligatoire}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextObligatoire' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextObligatoire"
                            primary="Obligatoire"
                            secondary="Ce type de cookies est nécessaire pour permettre au site de fonctionner normalement. Il ne contient aucune donnée personnelle."
                        />
                    </ListItem>

                    <ListItem key="preferences" role={undefined} dense button onClick={(event) => handleToggle(event, "preferences")}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={cookieStates.preferences}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextPreferences' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextPreferences"
                            primary="Préférences"
                            secondary="Ce type de cookies permet de sauvegarder vos préférences de navigation, afin de vous offrir la meilleure expérience utilisateur possible. Il ne contient aucune donnée permettant de vous identifier personnellement."
                        />
                    </ListItem>

                    <ListItem key="statistiques" role={undefined} dense button onClick={(event) => handleToggle(event, "statistiques")}>
                        <ListItemIcon>
                            <Checkbox
                                edge="start"
                                checked={cookieStates.statistiques}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextStatistiques' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextStatistiques"
                            primary="Statistiques"
                            secondary="Ce type de cookies permet d'étudier la fréquentation du site (nombre de visiteurs, pages les plus consultées...). Ces informations nous permettent d'améliorer et d'optimiser ce site, dans le but de vous offrir une expérience utilisateur la plus agréable possible. Il ne contient aucune donnée personnelle."
                        />
                    </ListItem>

                    <ListItem key="marketing" role={undefined} dense button disabled>
                        <ListItemIcon>
                            <Checkbox
                                disabled
                                edge="start"
                                checked={cookieStates.marketing}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': 'lblLstItemTextMarketing' }}
                            />
                        </ListItemIcon>
                        <ListItemText
                            id="lblLstItemTextMarketing"
                            primary="Marketing"
                            secondary="Ce type de cookies est utilisé pour proposer du contenu publicitaire adapté à chaque visiteur. Ce site n'utilise pas ce type de cookies."
                            disabled
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDiscard} color="primary">
                    Tout refuser
                </Button>
                <Button onClick={handleValid} color="primary">
                    Valider mon choix
                </Button>
            </DialogActions>
        </Dialog>
    );
}
