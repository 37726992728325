import React from "react";
import PropTypes from 'prop-types';

import {
  Link,
} from "react-router-dom";

import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import {
    MENU_ICON_MAPPING,
} from "PathConfig/components/ProjectSettings.jsx";





//
// Style propre au composant "LstMenuDrawer" - version 'Drawer' (mobile)
//
const useMenuDrawerStyles = makeStyles((theme) => ({
    drawerMenuBox: {
        marginTop: theme.spacing(2),
    },
    drawerMenuItemN1: {
        marginBottom: props => props.menu_kind === 'list' ? theme.spacing(2) : null,

        '&:hover': {
            backgroundColor: () => theme.palette.primary.main,
            '& $drawerMenuItemN1Link $drawerMenuItemN1Icon, $drawerMenuItemTextN1': {
                color: 'white'
            },
        },
    },
    drawerMenuItemN1Link: {
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    drawerMenuItemN1Icon: {
        marginRight: theme.spacing(3),
        width: '2rem',
        height: 'auto',
        color: props => theme.palette.primary.main,
    },
    drawerMenuItemTextN1: {
        color: theme.palette.primary.main,
    },
    drawerMenuItemN2: {
        padding: theme.spacing(0, 0, 0, 5),
    },
    drawerMenuItemN2Link: {
        textDecoration: 'none',
        color: theme.palette.secondary.main,
    },
}));



//
// Composant contenant le menu du site, pour la version mobile
//
export const LstMenuDrawer = ({ menu }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useMenuDrawerStyles({
        menu_kind: menu.kind,
    });



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    switch(menu.kind) {

        case "list":
            return (
                <>
                    {menu && menu.lst_items.map((menuItemN1) => {

                        // On récupère l'icone associée au menu courant à partie de la table
                        // de correspondance définie dans le fichier de paramétrage du projet
                        const CurrentIcon = MENU_ICON_MAPPING[menuItemN1.mainmenu.icon_name];

                        return (
                                <ListItem
                                    key={menuItemN1.mainmenu.id}
                                    component='li'
                                    className={classes.drawerMenuItemN1}
                                    button
                                >
                                    <Link
                                        className={classes.drawerMenuItemN1Link}
                                        to={menuItemN1.mainmenu.url}
                                    >
                                        {menuItemN1.mainmenu.icon_name &&
                                            <CurrentIcon
                                                className={classes.drawerMenuItemN1Icon}
                                            />
                                        }

                                        <ListItemText
                                            className={classes.drawerMenuItemTextN1}
                                            primary={menuItemN1.mainmenu.label}
                                        />
                                    </Link>
                                </ListItem>
                        );
                    })}
                </>
            );

        case "dropdown":
            return (
                <>
                    {menu && menu.lst_items.map((menuItemN1) =>
                        <Box
                            key={menuItemN1.mainmenu.id}
                            className={classes.drawerMenuBox}
                        >
                            <ListItem
                                className={classes.drawerMenuItemN1}
                                button
                            >
                                <ListItemText
                                    className={classes.drawerMenuItemTextN1}
                                    primary={menuItemN1.mainmenu.label}
                                />
                            </ListItem>
                            <List
                                disablePadding={true}
                            >
                                {menuItemN1.submenus.map((menuItemN2) =>
                                    <ListItem key={menuItemN2.id} button className={classes.drawerMenuItemN2}>
                                        <Link
                                            className={classes.drawerMenuItemN2Link}
                                            to={menuItemN2.url}
                                        >
                                            <ListItemText
                                                primary={menuItemN2.label}
                                            />
                                        </Link>
                                    </ListItem>
                                )}
                            </List>
                        </Box>
                    )}
                </>
            );

        default:
            return null;
    }
}

LstMenuDrawer.propTypes = {
    menu: PropTypes.object,
};
