import React from 'react';

import {
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    meteoPreviewCard: {
        height: '100%',
    },
    meteoPreviewCardHeader: {
        padding: theme.spacing(2),
        height: '30%',
    },
    meteoPreviewCardContent: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: '70%',
        padding: '0px',
    },
    meteoPreviewCardContentBox: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    meteoPreviewCardContentTitle: {
        fontWeight: 'bold',
    },
    meteoPreviewCardContentPeriod: {
        textDecoration: 'underline',
    },
    meteoPreviewCardContentIcon: {
        width: '5rem',
        height: '5rem',
    },
    meteoPreviewCardContentNoInfo: {
        textAlign: 'center',
        fontStyle: 'italic',
        marginBottom: 'auto',
    },
}));





//
// Composant représentant la météo du jour (matin / après-midi) présentée sous
// forme d'une carte.
//
export const MeteoSlide = (props) => {


    // On récupère les propriétés...
    const { meteo } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.meteoPreviewCard}>
            <CardHeader
                className={classes.meteoPreviewCardHeader}
                title="Météo"
            />
            <CardContent
                className={classes.meteoPreviewCardContent}
            >
                <Box className={classes.meteoPreviewCardContentBox}>
                    <Typography className={classes.meteoPreviewCardContentPeriod}>Matin</Typography>
                    {meteo.morning ?
                        <>
                            <Avatar
                                className={classes.meteoPreviewCardContentIcon}
                                src={`https://openweathermap.org/img/wn/${meteo.morning.icon}@4x.png`}
                                alt="Météo"
                            >
                                M
                            </Avatar>
                            <Typography>{meteo.morning.desc}</Typography>
                            <Typography>{meteo.morning.temp} - {meteo.morning.wind}</Typography>
                        </>
                    :
                        <Typography
                            className={classes.meteoPreviewCardContentNoInfo}
                        >
                            Aucune information disponible
                        </Typography>
                    }
                </Box>

                <Box className={classes.meteoPreviewCardContentBox}>
                    <Typography className={classes.meteoPreviewCardContentPeriod}>Après-midi</Typography>
                    {meteo.afternoon ?
                        <>
                            <Avatar
                                className={classes.meteoPreviewCardContentIcon}
                                src={`https://openweathermap.org/img/wn/${meteo.afternoon.icon}@4x.png`}
                                alt="Météo"
                            >
                                M
                            </Avatar>
                            <Typography>{meteo.afternoon.desc}</Typography>
                            <Typography>{meteo.afternoon.temp} - {meteo.afternoon.wind}</Typography>
                        </>
                    :
                        <Typography
                            className={classes.meteoPreviewCardContentNoInfo}
                        >
                            Aucune information disponible
                        </Typography>
                    }
                </Box>
            </CardContent>
        </Card>
    );
}
