import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Avatar,
    Box,
    Card,
    CardActionArea,
    CardContent,
    Chip,
    Divider,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentOrganisationListRoot: style => ({
        ...style.contentOrganisationListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentOrganisationListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentOrganisationListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentOrganisationListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentOrganisationListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentOrganisationListRoot.xl,
        },
    }),

    contentOrganisationListTitle: style => ({
        ...style.contentOrganisationListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentOrganisationListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentOrganisationListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentOrganisationListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentOrganisationListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentOrganisationListTitle.xl,
        },
    }),

    contentOrganisationListGridContainer: style => ({
        ...style.contentOrganisationListGridContainer.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentOrganisationListGridContainer.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentOrganisationListGridContainer.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentOrganisationListGridContainer.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentOrganisationListGridContainer.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentOrganisationListGridContainer.xl,
        },
    }),

    contentOrganisationListGridItem: style => ({
        ...style.contentOrganisationListGridItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentOrganisationListGridItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentOrganisationListGridItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentOrganisationListGridItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentOrganisationListGridItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentOrganisationListGridItem.xl,
        },
    }),

    contentOrganisationListCard: {
        height: '30vh',
    },
    contentOrganisationListCardActionArea: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },
    contentOrganisationListCardContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    contentOrganisationListCardContentBoxHeader: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    },
    contentOrganisationListCardContentAvatar: {
        marginRight: theme.spacing(2),
        width: '10vh',
        height: '10vh',

        '& img': {
            objectFit: 'contain',
        }
    },
    contentOrganisationListCardContentBoxTitle: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentOrganisationListCardName: {
        fontWeight: 'bold',
    },
    contentOrganisationListCardKind: {
        fontStyle: 'italic',
        fontSize: 'small',
        marginTop: theme.spacing(1),
    },
    contentOrganisationListCardActivity: {
        fontSize: 'small',
        marginTop: theme.spacing(1),
    },
    contentOrganisationListCardContentBoxChips: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: theme.spacing(1),
    },
    contentOrganisationListCardContentChipTag: {
        marginRight: theme.spacing(1),
    },
    contentOrganisationListCardContentDivider: {
        marginTop: theme.spacing(2),
    },
    contentOrganisationListCardContentDescription: {
        height: '100%',
        overflow: 'hidden',
        maskImage: 'linear-gradient(black 70%, transparent 100%)',
    },
}));





//
// Composant permettant d'afficher l'annuaire des entreprises (en mode 'preview')
//
export const ContentOrganisationList = (props) => {

    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentOrganisationListRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentOrganisationListTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentOrganisationListGridContainer: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentOrganisationListGridItem: {
                    common: {},
                    xs: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    sm: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    md: {
                        marginLeft: theme.spacing(2),
                        marginRight: theme.spacing(2),
                    },
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Rien à faire
    return (
        <Box
            className={classes.contentOrganisationListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentOrganisationListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.length > 0 ?
                <Grid
                    className={classes.contentOrganisationListGridContainer}
                    container
                    spacing={3}
                >
                    {data.map((organisation) => (
                        <Grid
                            className={classes.contentOrganisationListGridItem}
                            item
                            xs={12} sm={6} lg={4}
                            key={organisation.id}
                        >
                            <Card component="article" className={classes.contentOrganisationListCard}>
                                <CardActionArea
                                    className={classes.contentOrganisationListCardActionArea}
                                    component={Link}
                                    to={{ pathname: `/annuaire-des-entreprises/${organisation.id}-${organisation.name_slug}/` }}
                                >
                                    <CardContent className={classes.contentOrganisationListCardContent}>
                                        <Box className={classes.contentOrganisationListCardContentBoxHeader}>

                                            <Avatar
                                                className={classes.contentOrganisationListCardContentAvatar}
                                                variant="rounded"
                                                alt={organisation.name}
                                                src={organisation.logo_resized_src || ""}
                                                srcSet={organisation.logo_resized_srcset || ""}
                                            />

                                            <Box className={classes.contentOrganisationListCardContentBoxTitle}>
                                                <Typography component="h2" className={classes.contentOrganisationListCardName}>
                                                    {organisation.name}
                                                </Typography>

                                                <Typography component="span" variant="body1"  className={classes.contentOrganisationListCardKind}>
                                                    {organisation.kind_label}
                                                </Typography>

                                                <Typography component="span" variant="body1"  className={classes.contentOrganisationListCardActivity}>
                                                    {organisation.activity}
                                                </Typography>

                                                <Box className={classes.contentOrganisationListCardContentBoxChips}>
                                                    {organisation.tags.length > 0 && organisation.tags.split(";").map((tag, index) =>
                                                        <Chip
                                                            key={index}
                                                            className={classes.contentOrganisationListCardContentChipTag}
                                                            label={tag}
                                                            variant="outlined"
                                                        />
                                                    )}
                                                </Box>
                                            </Box>

                                        </Box>

                                        <Divider className={classes.contentOrganisationListCardContentDivider} />

                                        <Box
                                            className={classes.contentOrganisationListCardContentDescription}
                                            dangerouslySetInnerHTML={{ __html: organisation.description }}
                                        />
                                    </CardContent>

                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            :
                <Typography>
                    Aucune entreprise disponible pour le moment
                </Typography>
            }
        </Box>
    );
}
