import React from 'react';

import {
    useEffect,
    useState,
} from 'react';

import {
  Link,
} from "react-router-dom";

import {
    Avatar,
    Box,
    Button,
    CircularProgress,
    Chip,
    Divider,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    Autocomplete,
} from '@material-ui/lab';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import SearchIcon from '@material-ui/icons/Search';
import DescriptionIcon from '@material-ui/icons/Description';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import PhotoIcon from '@material-ui/icons/Photo';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

import {
    merge as lodashMerge
} from 'lodash';


// Hook utilisé pour dialoguer avec le serveur
import {
    useRequestData,
    RequestStatus,
} from 'PathCore/hooks/useRequestData.jsx';

// Importation du fichier de configuration
import {
    API_SEARCH_WEB_URL,
} from "PathWWW/components/WWWConfig.jsx";





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentSearchRoot: style => ({
        ...style.contentSearchRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSearchRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSearchRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSearchRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSearchRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSearchRoot.xl,
        },
    }),

    contentSearchTitle: style => ({
        ...style.contentSearchTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSearchTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSearchTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSearchTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSearchTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSearchTitle.xl,
        },
    }),

    contentSearchRootInput: style => ({
        ...style.contentSearchRootInput.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentSearchRootInput.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentSearchRootInput.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentSearchRootInput.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentSearchRootInput.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentSearchRootInput.xl,
        },
    }),

    contentSearchBoxInput: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    contentSearchAutocomplete: {
        flexGrow: 1,

        flexBasis: '100%',
        [theme.breakpoints.up('md')]: {
            flexBasis: 'unset',
            marginRight: theme.spacing(2),
        }
    },
    contentSearchNbResultsTextField: {
        fontStyle: 'italic',
    },
    contentSearchButton: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),

        flexBasis: '100%',
        [theme.breakpoints.up('md')]: {
            flexBasis: 'unset',
        }
    },
    contentSearchResultList: {
        marginTop: theme.spacing(4),
    },
    contentSearchResultLink: {
        textDecoration: 'none',
        color: 'unset',
    },
    contentSearchResultText: {
        display: 'flex',
        flexDirection: 'column',
    },
    contentSearchResultDivider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },

}));



// Callback utilisée par le hook 'useRequestData' pour transformer
// les données reçues par le serveur
const fetchMapFunc = (data) => {
    return data.map((item) => (
        {
            kind: item.kind,
            title: item.title,
            extraInfos: item.extra_infos,
            url: item.url,
            rank: item.rank,
        }
    ));
}



//
// Composant représentant une page de recherche
//
export const ContentSearch = (props) => {

    // On récupère les propriétés...
    const { title, params, style, data } = props;


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook utilisé pour récupérer les données depuis le serveur
    // Si tout s'est bien passé, alors "request.status === RequestStatus.SUCCESS" et
    // les données sont contenues dans "request.data"
    const [request, fetchRequest, abortRequest, resetRequest] = useRequestData();


    // Variables contenant les mots-clés saisis dans la zone de saisie
    // 'inputValue' contient la valeur de la zone de saisie
    const [inputValue, setInputValue] = React.useState('');

    // 'value' contient la liste de mots-clés déjà saisie (car saisie multiple autorisée)
    const [value, setValue] = React.useState([]);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Hook appelé lorsque la valeur contenue dans le 'TextField' a changé
    useEffect(() => {

        // Est-ce que un ou plusieurs mots-clés ont été saisis ?
        if(value.length > 0) {
            // OUI : on lance automatiquement la recherche
            handleClickSearch();
        }

    }, [value]);


    // Hook appelé lorsque l'utilisateur clique sur le bouton 'Rechercher'
    const handleClickSearch = () => {

        // Tous les mots-clés à rechercher sont contenus dans 'value'
        // Si des valeurs ont été saisies dans 'inputValue' alors elles
        // ont déjà été transférées dans 'value' lors du 'onBlur' de l'autocomplete

        // On utilise un objet de type 'URLSearchParams' pour construire la requête
        // Cela permet notamment de gérer les espaces dans les mots clés...
        let searchParams = new URLSearchParams();
        // On ajoute tous les mots-clés présents dans 'value'
        value.map((item) => {
            searchParams.append("keyword", item)
        })

        // On ajoute les mots-clés à rechercher à la fin de l'URL
        const urlAPIFetchData = `${API_SEARCH_WEB_URL}?${searchParams}`;

        // On lance une requête pour demander les données au serveur
        fetchRequest({
            url: urlAPIFetchData,
            options: { method: "GET", },
            mapFunc: fetchMapFunc,
        });

    }



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentSearchRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentSearchTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentSearchRootInput: {
                    common: {
                        paddingTop: theme.spacing(3),
                        paddingBottom: theme.spacing(3),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentSearchRoot}
        >
            {title &&
                <Typography
                    className={classes.contentSearchTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            <Box
                className={classes.contentSearchRootInput}
            >
                <Box
                    className={classes.contentSearchBoxInput}
                >
                    <Autocomplete
                        id="search-box"
                        multiple
                        className={classes.contentSearchAutocomplete}
                        disabled={request.status === RequestStatus.IN_PROGRESS}
                        freeSolo
                        value={value}
                        options={[]}
                        onChange={(event, newValue) => {
                            setValue(newValue);
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                            resetRequest();
                        }}
                        onBlur={(event) => {
                            // Avant de quitter la zone de saisie, on ajoute la valeur
                            // saisie par l'utilisateur contenue dans 'inputValue' (si elle existe)
                            // dans la liste 'value'
                            if(inputValue.trim()) {
                                setValue(value.concat(inputValue.trim()));
                                setInputValue("")
                            }
                        }}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                                <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                            ))
                        }
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputRef={(input) => input && input.focus()}
                                label="Rechercher sur tout le site"
                                margin="normal"
                                variant="outlined"
                            />
                        )}
                    />

                    <Button
                        className={classes.contentSearchButton}
                        variant="contained"
                        color="primary"
                        onClick={handleClickSearch}
                        disabled={(value.length === 0 && inputValue.length === 0) || request.status === RequestStatus.IN_PROGRESS}
                        startIcon={<SearchIcon />}
                    >
                        Rechercher
                    </Button>
                </Box>

                {request.status === RequestStatus.SUCCESS && request.data &&
                    <Box
                        className={classes.contentSearchNbResults}
                    >
                        <Typography
                            className={classes.contentSearchNbResultsTextField}
                        >
                            {request.data.length === 0 ?
                                "Aucun résultat trouvé"
                            :
                                request.data.length === 1 ?
                                    "1 résultat trouvé"
                                :
                                    `${request.data.length} résultats trouvés`
                            }
                        </Typography>
                    </Box>
                }
            </Box>

            <Box
                className={classes.contentSearchRootResults}
            >
                {request.status === RequestStatus.ERROR &&
                    <Box>
                        <Typography
                            variant="body1"
                            className={classes.loadingError}
                        >
                            Impossible de récupérer la liste des résultats ! Veuillez nous excuser pour la gêne occasionnée...
                        </Typography>
                    </Box>
                }

                {request.status === RequestStatus.IN_PROGRESS &&
                    <Box
                        className={classes.loadingBox}
                    >
                        <CircularProgress />
                        <Typography
                            variant="overline"
                        >
                            RECHERCHE EN COURS...
                        </Typography>
                    </Box>
                }

                {request.status === RequestStatus.SUCCESS && request.data && request.data.length > 0 &&
                    <List className={classes.contentSearchResultList}>

                        {request.data.sort((a, b) => b.rank - a.rank).map((item, index) =>
                            <Link
                                key={index}
                                to={{ pathname: item.url }}
                                className={classes.contentSearchResultLink}
                                target={
                                    (item.kind === "article" || item.kind === "news")
                                    ? "_self"
                                    : "_blank"
                                }
                                rel={(item.kind === "resource_photo" || item.kind === "resource_document") ? "noopener noreferrer" : undefined}
                            >
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar>
                                            {item.kind === "article" && <DescriptionIcon />}
                                            {item.kind === "news" && <ChatBubbleIcon />}
                                            {item.kind === "resource_photo" && <PhotoIcon />}
                                            {item.kind === "resource_document" && <PictureAsPdfIcon />}
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            <Box
                                                className={classes.contentSearchResultText}
                                            >
                                                <Typography
                                                    component="span"
                                                    variant="body1"
                                                    className={classes.inline}
                                                    color="textPrimary"
                                                >
                                                    {item.title}
                                                </Typography>
                                                <Typography
                                                    component="span"
                                                    variant="body2"
                                                    className={classes.inline}
                                                    color="textSecondary"
                                                >
                                                    {item.extraInfos}
                                                </Typography>
                                            </Box>
                                        }
                                    />
                                </ListItem>

                                <Divider component="li" className={classes.contentSearchResultDivider} />
                            </Link>
                        )}

                    </List>
                }
            </Box>
        </Box>
    );
}
