import {
    createMuiTheme,
} from '@material-ui/core/styles';

import {
    frFR
} from '@material-ui/core/locale';

// Importation du fichier de configuration propre au projet
import {
    PRIMARY_COLOR,
    SECONDARY_COLOR,
} from "PathConfig/components/ProjectSettings.jsx";





// Création du thème : on récupère le thème par défaut de MUI
// et on charge les locales françaises
const theme = createMuiTheme({
    palette: {
        primary: PRIMARY_COLOR,
        secondary: SECONDARY_COLOR,
    },

    typography: {
        fontFamily: 'Lato, Arial',

        h1: {
            display: "block;",
            width: "100%",
            color: PRIMARY_COLOR[500],
            marginTop: '3rem',
            marginBottom: '1rem',
            fontSize: "1.5rem",
            padding: "8px 0px",
            textTransform: "uppercase",
            fontWeight: "bold",
            borderBottomWidth: "3px",
            borderBottomStyle: "solid",
            borderBottomColor: PRIMARY_COLOR[500],
        },
    },
}, frFR);



//
// Modification du thème :
// - la couleur des liens est la couleur "secondary"
//
theme.overrides = {
    ...theme.overrides,
    MuiLink: {
        ...theme.MuiLink,
        root: {
            ...theme.root,
            color: theme.palette.secondary.main,
        },
        underlineHover: {
            ...theme.underlineHover,
            '&:hover': {
                color: theme.palette.secondary.dark,
            }
        },
    },
    MuiTypography: {
        h1: {
            [theme.breakpoints.down('md')]: {
                width: 'unset',
                marginLeft: theme.spacing(2),
                marginRight: theme.spacing(2),
            },
        }
    }
}


export default theme;