import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    format as date_fns_format,
    formatISO,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";

import {
    merge as lodashMerge
} from 'lodash';


import { compute_str_date_range } from 'PathCore/tools/utils.jsx';





//
// Style propre au composant 'NewsSlide'
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useHappeningSlideStyles = () => makeStyles((theme) => ({

    happeningPreviewCard: style => ({
        ...style.happeningPreviewCard.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCard.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCard.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCard.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCard.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCard.xl,
        },
    }),

    happeningPreviewCardActionArea: style => ({
        ...style.happeningPreviewCardActionArea.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCardActionArea.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCardActionArea.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCardActionArea.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCardActionArea.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCardActionArea.xl,
        },
    }),

    happeningPreviewCardMedia: style => ({
        ...style.happeningPreviewCardMedia.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCardMedia.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCardMedia.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCardMedia.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCardMedia.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCardMedia.xl,
        },
    }),

    happeningPreviewCardContent: style => ({
        ...style.happeningPreviewCardContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCardContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCardContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCardContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCardContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCardContent.xl,
        },
    }),

    happeningPreviewCardContentTitle: style => ({
        ...style.happeningPreviewCardContentTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCardContentTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCardContentTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCardContentTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCardContentTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCardContentTitle.xl,
        },
    }),

    happeningPreviewCardContentPlaceDate: style => ({
        ...style.happeningPreviewCardContentPlaceDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCardContentPlaceDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCardContentPlaceDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCardContentPlaceDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCardContentPlaceDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCardContentPlaceDate.xl,
        },
    }),

    happeningPreviewCardContentSignatureDate: style => ({
        ...style.happeningPreviewCardContentSignatureDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.happeningPreviewCardContentSignatureDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.happeningPreviewCardContentSignatureDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.happeningPreviewCardContentSignatureDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.happeningPreviewCardContentSignatureDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.happeningPreviewCardContentSignatureDate.xl,
        },
    }),

}));



//
// Composant représentant une manifestation présentée sous
// forme d'une carte.
//
export const HappeningSlide = ({ happening, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useHappeningSlideStyles()(
        {
            happeningPreviewCard: {
                common: {
                    height: '100%',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            happeningPreviewCardActionArea: {
                common: {
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            happeningPreviewCardMedia: lodashMerge(
                {
                    common: {
                        width: '100%',
                        height: '60%',
                        objectFit: 'cover',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                (happening && happening.img_header && happening.img_header.style) || {},
            ),

            happeningPreviewCardContent: {
                common: {
                    width: '100%',
                    height: '40%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            happeningPreviewCardContentTitle: {
                common: {
                    fontSize: '1.2rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            happeningPreviewCardContentPlaceDate: {
                common: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '100%',
                    textAlign: 'center',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },

            happeningPreviewCardContentSignatureDate: {
                common: {
                    paddingTop: theme.spacing(2),
                    fontSize: '0.8rem',
                    fontStyle: 'italic',
                    opacity: '0.8',
                    marginTop: 'auto',
                },
                xs: {},
                sm: {},
                md: {},
                lg: {},
                xl: {},
            },
        },
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Mise en forme des dates
    let str_date_publication_start = happening.date_publication_start && date_fns_format(parseISO(happening.date_publication_start), 'dd/MM/yyyy', {locale: fr});


    // Calcul de l'intervalle de date de début / date de fin
    //
    // On commence par convertir toutes les chaînes en objets 'Date'
    const lst_dates_start = happening.lst_dates.map((current) => current.date_start && parseISO(current.date_start));
    const lst_dates_end = happening.lst_dates.map((current) => current.date_end && parseISO(current.date_end));

    // On récupère la première date de début et la dernière date de fin de la manifesation
    const min_date = lst_dates_start.reduce((a, b) => a <= b ? a : b);
    const max_date = lst_dates_end.reduce((a, b) => a >= b ? a : b);

    // On construit la chaîne à afficher en fonction des dates calculées précédemment
    const str_date_happening = compute_str_date_range(
        formatISO(min_date),
        formatISO(max_date),
        false,
        false
    );


    return (
        <Card className={classes.happeningPreviewCard}>
            <CardActionArea
                className={classes.happeningPreviewCardActionArea}
                component={Link}
                to={{ pathname: happening.url_detail }}
            >
                {happening.img_header.src &&
                    <CardMedia
                        className={classes.happeningPreviewCardMedia}
                        component="img"
                        src={happening.img_header.src}
                        srcSet={happening.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                        title={`${happening.img_header.legend} - ${happening.img_header.credits}`}
                        alt={happening.img_header.alt}
                    />
                }
                <CardContent
                    className={classes.happeningPreviewCardContent}
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.happeningPreviewCardContentTitle}
                    >
                        {happening.title}
                    </Typography>


                    <Box
                        className={classes.happeningPreviewCardContentPlaceDate}
                    >
                        <Typography
                            variant="body2"
                        >
                            {happening.place}
                        </Typography>

                        <Typography
                            variant="body2"
                        >
                            {str_date_happening}
                        </Typography>
                    </Box>

                    <Box
                        component="span"
                        className={classes.happeningPreviewCardContentSignatureDate}
                    >
                        Publié le {str_date_publication_start} par {happening.signature}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
