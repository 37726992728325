import React from 'react';
import PropTypes from 'prop-types';

import {
    useEffect,
    useState,
    useRef,
} from 'react';

import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    TextField,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import DraftsIcon from '@material-ui/icons/Drafts';


// Importation du fichier de configuration de l'intranet
import {
    API_NEWSLETTER_ADD_USER_URL,
} from "PathIntranet/components/IntranetConfig.jsx";





//
// Boite de dialogue demandant à l'utilisateur de confirmer son inscription à la newsletter
//
const NewsletterSignUpConfirmDialog = ({ open, email, onClose, ...other }) => {


    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée quand l'utilisateur annule son inscrition
    const handleCancel = () => {
        // On ferme la boite de dialogue et on prévient la fenêtre principale
        // que l'utilisateur a cliqué sur le bouton 'Annuler' (= paramètre 'false')
        onClose(false);
    };


    // Callback appelée quand l'utilisateur valide son inscrition
    const handleOk = () => {
        // On ferme la boite de dialogue et on prévient la fenêtre principale
        // que l'utilisateur a cliqué sur le bouton 'Valider' (= paramètre 'true')
        onClose(true);
    };



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="newsletter-signup-confirmation-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="newsletter-signup-confirmation-dialog-title">Confirmer votre inscription</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    En cliquant sur le bouton "valider", vous acceptez de recevoir notre bulletin d'information sous format électronique, à l'adresse suivante :
                </DialogContentText>

                <DialogContentText>
                    {email}
                </DialogContentText>

                <DialogContentText>
                    À tout moment, vous pourrez décider de ne plus recevoir ce bulletin d'information électronique en cliquant sur le lien de désinscription, présent en bas de chaque bulletin.
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleCancel} color="primary">
                    Annuler
                </Button>
                <Button autoFocus onClick={handleOk} color="primary">
                    S'inscrire
                </Button>
            </DialogActions>
        </Dialog>
    );
}

NewsletterSignUpConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  email: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};





//
// Boite de dialogue utilisée pour prévenir l'utilisateur du résultat de son
// inscription à la newsletter (succès, échec...)
//
const ResultSignUpDialog = ({open, title, content, success, handleOk, ...other}) => {


    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Dialog
            maxWidth="xs"
            aria-labelledby="newsletter-signup-dialog-title"
            open={open}
            {...other}
        >
            <DialogTitle id="newsletter-signup-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {content}
                </DialogContentText>
            </DialogContent>

            <DialogActions>
                <Button autoFocus onClick={handleOk} color="primary">
                    Ok
                </Button>
            </DialogActions>
        </Dialog>
    );
}





// Style propre au composant 'Formulaire d'inscription à la newsletter'
const useNewsletterFormEmailStyles = makeStyles((theme) => ({
    formInProgressBox: {
        marginTop: '1rem',
        display: 'grid',
        gridTemplateRows: '1fr',
        gridTemplateColumns: '1fr',
    },
    footerFormNewsletter: {
        gridRow: 1,
        gridColumn: 1,
        display: 'flex',
        flexDirection: 'column',
    },
    footerButtonNewsletter: {
        marginTop: '1rem',
        marginLeft: 'auto',
    },
    inProgressBox: {
        gridRow: 1,
        gridColumn: 1,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));



//
// Composant utilisé pour gérer l'inscription d'un utilisateur à la newsletter
// Ce composant est constitué d'un 'TextField' permettant de saisir une adresse mail,
// ainsi qu'un bouton permettant de valider l'inscription
//
export const NewsletterFormEmail = (props) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Variable contenant la valeur saisie par l'utilisateur dans le 'TextField'
    const [email, setEmail] = useState("");

    // Référence vers le 'textfield' HTML, utilisée pour récupérer directement le status du champ HTML 'validity'
    const txtEmailRef = useRef(null);

    // Variable indiquant si la boite de dialogue demandant confirmation
    // de l'inscription doit être affichée
    const [open, setOpen] = useState(false);

    // Variable indiquant si la boite de dialogue affichant le résultat de
    // l'inscription doit être affichée
    const [dlgResultStatus, setDlgResultStatus] = useState({
        title: "",
        content: "",
        success: undefined,
    });

    // Variable indiquant si le processus d'inscription (entre le serveur web et mautic)
    // est en cours, pour savoir notamment si le champ et le bouton doivent être désactivés
    // et le 'CircularProgress' doit être affiché
    const [inProgress, setInProgress] = useState(false);

    // Variable permettant de contrôler le status du 'TextField' (activé / désactivé)
    const [isTxtEmailNewsletterDisabled, setTxtEmailNewsletterDisabled] = useState(false);

    // Variable permettant de contrôler le status du 'Button' (activé / désactivé)
    const [isBtnNewsletterSignUpDisabled, setBtnNewsletterSignUpDisabled] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque le contenu du 'textfield' est modifié
    const handleTxtEmailNewsletterChange = (event) => {
        // On met à jour la valeur de la variable 'email'
        setEmail(event.target.value);
    };


    // Callback appelée à chaque fois que la valeur contenue dans le 'textfield' (permettant
    // de saisir l'adresse électronique pour l'inscription à la newsletter) est modifiée
    useEffect(() => {

        // On récupère la validité du champs, calculée par le navigateur
        // Ici, on travaille directement sur le champs HTML et non pas 'react'. On passe donc via une référence pour attaquer ce champ
        txtEmailRef.current && setBtnNewsletterSignUpDisabled(txtEmailRef.current.value.length === 0 || !txtEmailRef.current.validity.valid);

    }, [email]);


    // Callback appelée lorsque l'utilisateur a cliqué sur le bouton "s'inscrire"
    const handleBtnNewsletterSignUpClick = (event) => {
        // On ouvre la boite de dialogue demandant confirmation
        setOpen(true);
    };


    // Callback appelée lorsque l'utilisateur a cliqué sur un des boutons de la
    // boite de dialogue de confirmation de l'inscription
    // Le paramètre 'signup' est un booléen indiquant si l'utilisateur a confirmé
    // ou annulé son inscrption
    const handleNewsletterSignUpConfirmClose = (signup) => {
        // On ferme la boite de dialogue
        setOpen(false);

        // Est-ce l'utilisateur a confirmé son inscription ?
        if(signup) {
            // OUI : on affiche un 'CircularProgress' le temps du traitement
            setInProgress(true);

            // On envoie une requête au serveur demandant d'ajouter l'adresse mail à la liste
            fetch(API_NEWSLETTER_ADD_USER_URL, {
                method: "POST",
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "email": email,
                })
            })
            .then((response) => {

                // La requête s'est bien passée... est-ce que le serveur est content ?
                if(response.ok) {
                    // Oui, tout s'est bien passé : on prévient l'utilisateur
                    setDlgResultStatus({
                        title: "Inscription validée",
                        content: "Nous vous remercions pour votre inscription. À très bientôt !",
                        success: true,
                    })
                } else {
                    // Une erreur est survenue, côté serveur : on l'affiche
                    setDlgResultStatus({
                        title: "Une erreur est survenue !",
                        content: "Une erreur est survenue durant votre inscription. Veuillez réessayer ultérieurement. Si le problème persiste, n'hésitez pas à nous contacter. Erreur : " + JSON.stringify(response.status + " - " + response.statusText),
                        success: false,
                    })
                }

                // Dans tous les cas, on vide le champ
                setEmail("");

            })
            .catch((response) => {

                // Une erreur est survenue !
                setDlgResultStatus({
                    title: "Une erreur est survenue",
                    content: "Une erreur est survenue durant votre inscription. Veuillez réessayer ultérieurement. Si le problème persiste, n'hésitez pas à nous contacter. Erreur : " + JSON.stringify(response.status + " - " + response.statusText),
                    success: false,
                })

            })
        }
    };


    // Callback appelée lorsque l'utilisateur a cliqué sur le bouton 'ok'
    // de la boite de dialogue affichant le résultat de l'inscription
    const handleResultSignUpDialogOk = (event) => {
        // On nettoie tout...
        setDlgResultStatus({
            title: "",
            content: "",
            success: undefined,
        })
        setInProgress(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useNewsletterFormEmailStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box className={classes.formInProgressBox}>
            <form
                className={classes.footerFormNewsletter}
                autoComplete="on"
            >
                <TextField
                    inputRef={txtEmailRef}
                    id="txtEmailNewsletter"
                    label="Adresse électronique"
                    type="email"
                    required
                    variant="outlined"
                    value={email}
                    disabled={isTxtEmailNewsletterDisabled || inProgress}
                    onChange={handleTxtEmailNewsletterChange}
                />

                <Button
                    id="btnNewsletterSignUp"
                    className={classes.footerButtonNewsletter}
                    variant="contained"
                    color="primary"
                    endIcon={<DraftsIcon />}
                    disabled={isBtnNewsletterSignUpDisabled || inProgress}
                    onClick={handleBtnNewsletterSignUpClick}
                >
                    S'inscrire
                </Button>
            </form>

            {inProgress &&
                <Box className={classes.inProgressBox}>
                    <CircularProgress />
                </Box>
            }

            {/* Boite de dialogue utilisée pour confirmer l'inscription */}
            <NewsletterSignUpConfirmDialog
                classes={{
                    paper: classes.paper,
                }}
                keepMounted
                open={open}
                onClose={handleNewsletterSignUpConfirmClose}
                email={email}
            />

            {/* Boite de dialogue utilisée pour prévenir l'utilisateur du résultat de l'inscription */}
            <ResultSignUpDialog
                open={dlgResultStatus.success !== undefined}
                title={dlgResultStatus.title}
                content={dlgResultStatus.content}
                success={dlgResultStatus.success}
                handleOk={handleResultSignUpDialogOk}
            />
        </Box>
    );
};
