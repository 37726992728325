import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentError500Root: style => ({
        ...style.contentError500Root.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentError500Root.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentError500Root.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentError500Root.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentError500Root.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentError500Root.xl,
        },
    }),

    contentError500Text: style => ({
        ...style.contentError500Text.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentError500Text.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentError500Text.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentError500Text.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentError500Text.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentError500Text.xl,
        },
    }),

}));


//
// Composant permettant d'afficher une page d'erreur 500.
//
export const ContentError500 = (props) => {


    const { title, params, style, data } = props;


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentError500Root: {
                    common: {
                        width: '100%',
                        height: 'inherit',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentError500Text: {
                    common: {
                        fontWeight: 'bold',
                        color: theme.palette.error.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentError500Root}
        >
            <Typography
                className={classes.contentError500Text}
                variant="body1"
            >
                Une erreur est survenue ! Veuillez nous excuser pour la gêne occasionnée.
            </Typography>
        </Box>
    );
}
