import React from 'react';

import {
    useRef,
    useState,
} from 'react';

import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import SendIcon from '@material-ui/icons/Send';

// Bibliothèque 'formik', utilisée pour la gestion des formulaires
import {
    Formik,
    Form,
} from 'formik';

// Bibliothèque 'yup', utilisée pour la validation des formulaires
import * as Yup from 'yup';


// Hook permettant d'afficher un message dans une 'snackbar'
import {
    useUserMessages,
} from "PathCore/contexts/UserMessageContext.jsx"

import { FormikTextField } from "PathCore/fields/FormikTextField.jsx";
import { FormikCheckboxField } from "PathCore/fields/FormikCheckboxField.jsx";
import { FormikReCaptchaField } from "PathCore/fields/FormikReCaptchaField.jsx";

import {
    API_CONTACT_FORM_URL,
} from "PathWWW/components/WWWConfig.jsx";

import {
    GOOGLE_RECAPTCHA_SITE_KEY,
} from "PathConfig/components/ProjectSettings.jsx";





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    contactFormRoot: {
        display: "flex",
        flexDirection: "column",
    },
    contactFormField: {
        marginBottom: theme.spacing(2),
    },
    contactFormGTCU: {
        fontSize: "0.8rem",
    },
    contactFormGTCUHelper: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: "auto",
            marginRight: "auto",
        },
    },
    contactFormButtonGrid: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    }
}));



// Schéma de validation, utilisé par Formik pour valider les données du formulaire
const validationSchema = Yup.object().shape({
    name: Yup
        .string()
        .required("Veuillez saisir votre nom et votre prénom"),
    email: Yup
        .string()
        .email("Veuillez saisir une adresse électronique valide")
        .when('phone', {
            is: (phone) => !phone || phone.length === 0,
            then: Yup.string().email().required("Veuillez saisir votre adresse électronique ou votre numéro de téléphone"),
            otherwise: Yup.string().email("Veuillez saisir une adresse électronique valide")
        }),
    phone: Yup
        .string("Veuillez saisir un numéro de téléphone valide")
        .when('email', {
            is: (email) => !email || email.length === 0,
            then: Yup.string().required("Veuillez saisir votre adresse électronique ou votre numéro de téléphone"),
            otherwise: Yup.string("Veuillez saisir un numéro de téléphone valide")
        }),
    subject: Yup
        .string()
        .required("Veuillez saisir l'objet de votre demande"),
    message: Yup
        .string()
        .required("Veuillez saisir votre message"),
    gcu: Yup
        .boolean()
        .oneOf([true], "Veuillez accepter les conditions d'utilisation"),
    captcha: Yup
        .string()
        .nullable()
        .required("Veuillez valider le captcha"),
}, [ [ 'email', 'phone' ] ]);



// Valeurs par défaut du formulaire, utilisées lors d'une création
const initialValues = {
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
    gcu: false,
    captcha: "",
};



//
// Composant utilisé pour afficher le formulaire de contact.
//
export function ContactForm({ labelGCU }) {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Création d'une référence vers le composant interne du 'ReCaptcha'
    // Utilisé notamment pour appeler directement la méthode 'reset' du composant interne
    const recaptchaRef = useRef();

    // Message à afficher dans la boite de dialogue
    // Doit être de la forme : {titre: "...", contenu: "..."}
    const [dlgMessage, setDlgMessage] = useState(null);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lorsque l'utilisateur a fermé la boite de dialogue
    const handleClose = (event) => {
        // On efface le message à afficher pour ferme la boite de dialogue
        setDlgMessage(null);
    }



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                validateOnChange
                onSubmit={
                    async (values, actions) => {

                        let response = null;
                        let body = null;

                        try {
                            // On envoie les informations contenues dans le formulaire au serveur
                            response = await fetch(
                                API_CONTACT_FORM_URL,
                                {
                                    method: "POST",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify(values),
                                }
                            );

                        } catch(error) {

                            // Une erreur est survenue : on l'affiche !
                            setDlgMessage({
                                title: "Une erreur est survenue !",
                                content: "Votre message n'a pas pu être envoyé correctement. Veuillez réessayer ultérieurement. Nous nous excusons pour la gêne occasionnée.",
                            })

                        }


                        // La requête s'est bien passée... est-ce que le serveur est content ?
                        if(response.ok) {
                            // OUI, tout s'est bien passé :

                            // - on indique que le processus de soumission est fini
                            actions.setSubmitting(false);

                            // - on nettoie le formulaire
                            actions.resetForm();

                            // - on réinitialise le captcha
                            recaptchaRef && recaptchaRef.current.reset();

                            // - on prévient l'utilisateur
                            setDlgMessage({
                                title: "Demande envoyée",
                                content: "Votre demande a bien été envoyée. Nous vous répondrons dans les plus brefs délais.",
                            })

                        } else {

                            // La requête s'est bien passée mais le serveur a retourné une erreur...
                            try {

                                // On récupère le message d'erreur retourné par le serveur
                                body = await response.json();

                                // et on l'affiche
                                setDlgMessage({
                                    title: "Une erreur est survenue !",
                                    content: body,
                                })

                            } catch(error) {

                                // Une erreur est survenue : on l'affiche !
                                setDlgMessage({
                                    title: "Une erreur est survenue !",
                                    content: "Votre message n'a pas pu être envoyé correctement. Veuillez réessayer ultérieurement. Nous nous excusons pour la gêne occasionnée.",
                                })

                            }

                        }
                    }
                }
            >
                {({
                    dirty,
                    isSubmitting,
                    isValid,
                }) => {
                    return (
                        <Form className={classes.contactFormRoot}>
                            <FormikTextField
                                name="name"
                                label="Nom et prénom"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <FormikTextField
                                name="email"
                                label="Adresse électronique"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />

                            <FormikTextField
                                name="phone"
                                label="Numéro de téléphone"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                            />

                            <FormikTextField
                                name="subject"
                                label="Objet de la demande"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <FormikTextField
                                name="message"
                                label="Message"
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                multiline
                                rows={4}
                                rowsMax={10}
                                required
                            />

                            <FormikCheckboxField
                                id="gcu"
                                name="gcu"
                                label={
                                    <Box
                                        dangerouslySetInnerHTML={{ __html: labelGCU }}
                                    />
                                }
                                margin="normal"
                                variant="outlined"
                                fullWidth
                                required
                            />

                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6}>
                                    <FormikReCaptchaField
                                        id="captcha"
                                        name="captcha"
                                        recaptchaRef={recaptchaRef}
                                        sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                                        margin="normal"
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.contactFormButtonGrid}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        disabled={!isValid || (!dirty || isSubmitting)}
                                        endIcon={<SendIcon />}
                                        type="submit"
                                    >
                                        Envoyer
                                    </Button>
                                </Grid>
                            </Grid>

                        </Form>
                    );
               }}
            </Formik>

            {/* Boite de dialogue permettant d'afficher un éventuel message d'erreur */}
            <Dialog
                open={Boolean(dlgMessage)}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-content"
            >
                <DialogTitle id="alert-dialog-title">{dlgMessage && dlgMessage.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-content">
                        {dlgMessage && dlgMessage.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
