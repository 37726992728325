import React from 'react';
import PropTypes from 'prop-types';

import {
    Link,
    NavLink,
} from "react-router-dom";

import {
    Box,
    Button,
    List,
    ListItem,
    MenuItem,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import Menu from 'material-ui-popup-state/HoverMenu';

import {
    usePopupState,
    bindHover,
    bindMenu,
} from 'material-ui-popup-state/hooks'

import {
    merge as lodashMerge
} from 'lodash';


import {
    MENU_ICON_MAPPING,
} from "PathConfig/components/ProjectSettings.jsx";





const useMainMenuListStyles = () => makeStyles((theme) => ({

    mainMenuListLi: style => ({
        ...style.mainMenuListLi.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuListLi.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuListLi.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuListLi.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuListLi.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuListLi.xl,
        },
    }),

    mainMenuListLink: style => ({
        ...style.mainMenuListLink.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuListLink.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuListLink.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuListLink.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuListLink.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuListLink.xl,
        },
    }),

    mainMenuListLinkActive: style => ({
        ...style.mainMenuListLinkActive.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuListLinkActive.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuListLinkActive.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuListLinkActive.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuListLinkActive.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuListLinkActive.xl,
        },
    }),

    mainMenuListBox: style => ({
        ...style.mainMenuListBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuListBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuListBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuListBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuListBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuListBox.xl,
        },
    }),

    mainMenuListIcon: style => ({
        ...style.mainMenuListIcon.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuListIcon.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuListIcon.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuListIcon.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuListIcon.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuListIcon.xl,
        },
    }),

    mainMenuListLabel: style => ({
        ...style.mainMenuListLabel.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuListLabel.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuListLabel.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuListLabel.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuListLabel.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuListLabel.xl,
        },
    }),

}));



export const MainMenuList = ({ menuItem, params, style }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useMainMenuListStyles()(
        lodashMerge(
            {
                mainMenuListLi: {
                    common: {
                        marginLeft: '0.1rem',
                        marginRight: '0.1rem',
                        padding: 0,  // Pas de padding pour que toute la zone soit cliquable via le <a></a>
                        flexGrow: 1,
                        flexShrink: 1,
                        flexBasis: '0px',
                        '&:hover': {
                            backgroundColor: theme.palette.primary.main,
                            '& $mainMenuListIcon, $mainMenuListLabel': {
                                color: 'white'
                            },
                        },
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuListLink: {
                    common: {
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textDecoration: 'none',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuListLinkActive: {
                    common: {
                        backgroundColor: theme.palette.primary.main,
                        '& $mainMenuListIcon, $mainMenuListLabel': {
                            color: 'white'
                        },
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuListBox: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuListIcon: {
                    common: {
                        marginTop: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        width: 'auto',
                        height: '4rem',
                        color: theme.palette.secondary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuListLabel: {
                    common: {
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                        marginBottom: theme.spacing(1),
                        textAlign: 'center',
                        fontSize: '0.9rem',
                        fontWeight: 'normal',
                        color: theme.palette.secondary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // On récupère l'icone associée au menu courant à partie de la table
    // de correspondance définie dans le fichier de paramétrage du projet
    const CurrentIcon = MENU_ICON_MAPPING[menuItem.mainmenu.icon_name];

    return (
        <ListItem
            className={classes.mainMenuListLi}
        >
            <NavLink
                className={classes.mainMenuListLink}
                to={menuItem.mainmenu.url}
                activeClassName={classes.mainMenuListLinkActive}
            >
                <Box
                    className={classes.mainMenuListBox}
                >
                    {menuItem.mainmenu.icon_name &&
                        <CurrentIcon
                            className={classes.mainMenuListIcon}
                        />
                    }

                    <Typography
                        className={classes.mainMenuListLabel}
                    >
                        { menuItem.mainmenu.label }
                    </Typography>
                </Box>
            </NavLink>
        </ListItem>
    );
}

MainMenuList.propTypes = {
    menuItem: PropTypes.object,
    params: PropTypes.object,
    style: PropTypes.object,
};





//
// Style associé au composant représentant un menu de niveau 1
//
const useMainMenuDropdownStyles = () => makeStyles((theme) => ({

    mainMenuDropdownButton: style => ({
        ...style.mainMenuDropdownButton.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuDropdownButton.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuDropdownButton.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuDropdownButton.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuDropdownButton.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuDropdownButton.xl,
        },
    }),

    mainMenuDropdownLink: style => ({
        ...style.mainMenuDropdownLink.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuDropdownLink.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuDropdownLink.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuDropdownLink.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuDropdownLink.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuDropdownLink.xl,
        },
    }),

    mainMenuDropdownMenuItem: style => ({
        ...style.mainMenuDropdownMenuItem.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuDropdownMenuItem.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuDropdownMenuItem.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuDropdownMenuItem.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuDropdownMenuItem.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuDropdownMenuItem.xl,
        },
    }),

}));



//
// Composant représentant un menu de niveau 1 avec les 'menuItems' associés
//
const MainMenuDropdown = ({ menuItem, params, style }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Hook permettant de contrôler l'état de la 'popup' courante
    const popupState = usePopupState({
        variant: 'popover',
        popupId: `menu-item-${menuItem.mainmenu.id}`
    })



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useMainMenuDropdownStyles()(
        lodashMerge(
            {
                mainMenuDropdownButton: {
                    common: {
                        color: 'white',
                        fontSize: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuDropdownLink: {
                    common: {
                        textDecoration: 'none',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuDropdownMenuItem: {
                    common: {
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <Button
                className={classes.mainMenuDropdownButton}
                {...bindHover(popupState)}
                endIcon={<ArrowDropDownIcon />}
            >
                {menuItem.mainmenu.label}
            </Button>

            <Menu
                {...bindMenu(popupState)}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            >
                {menuItem.submenus.map((submenuItem) =>
                    <Link
                        key={submenuItem.id}
                        className={classes.mainMenuDropdownLink}
                        to={submenuItem.url}
                    >
                        <MenuItem
                            className={classes.mainMenuDropdownMenuItem}
                            onClick={popupState.close}
                        >
                            {submenuItem.label}
                        </MenuItem>
                    </Link>
                )}
            </Menu>
        </>
    )
}

MainMenuDropdown.propTypes = {
    menuItem: PropTypes.object,
    params: PropTypes.object,
    style: PropTypes.object,
};





//
// Style propre au composant
//
const useMainMenuStyles = () => makeStyles((theme) => ({

    mainMenuBar: style => ({
        ...style.mainMenuBar.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuBar.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuBar.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuBar.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuBar.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuBar.xl,
        },
    }),

    mainMenuContainer: style => ({
        ...style.mainMenuContainer.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuContainer.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuContainer.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuContainer.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuContainer.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuContainer.xl,
        },
    }),

    mainMenuLogoLink: style => ({
        ...style.mainMenuLogoLink.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuLogoLink.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuLogoLink.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuLogoLink.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuLogoLink.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuLogoLink.xl,
        },
    }),

    mainMenuLogoBox: style => ({
        ...style.mainMenuLogoBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuLogoBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuLogoBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuLogoBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuLogoBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuLogoBox.xl,
        },
    }),

    mainMenuLogoImg: style => ({
        ...style.mainMenuLogoImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuLogoImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuLogoImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuLogoImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuLogoImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuLogoImg.xl,
        },
    }),

    mainMenuNav: style => ({
        ...style.mainMenuNav.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuNav.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuNav.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuNav.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuNav.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuNav.xl,
        },
    }),

    mainMenuUl: style => ({
        ...style.mainMenuUl.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuUl.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuUl.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuUl.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuUl.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuUl.xl,
        },
    }),

    mainMenuDropdowns: style => ({
        ...style.mainMenuDropdowns.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuDropdowns.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuDropdowns.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuDropdowns.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuDropdowns.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuDropdowns.xl,
        },
    }),

    mainMenuLabelsBox: style => ({
        ...style.mainMenuLabelsBox.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuLabelsBox.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuLabelsBox.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuLabelsBox.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuLabelsBox.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuLabelsBox.xl,
        },
    }),

    mainMenuLabel1: style => ({
        ...style.mainMenuLabel1.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuLabel1.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuLabel1.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuLabel1.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuLabel1.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuLabel1.xl,
        },
    }),

    mainMenuLabel2: style => ({
        ...style.mainMenuLabel2.common,

        [theme.breakpoints.only('xs')]: {
            ...style.mainMenuLabel2.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.mainMenuLabel2.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.mainMenuLabel2.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.mainMenuLabel2.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.mainMenuLabel2.xl,
        },
    }),
}));



//
// Composant représentant le menu principal du site
//
export const MainMenu = ({ logo, label, menu, params, style }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useMainMenuStyles()(
        lodashMerge(
            {
                mainMenuBar: {
                    common: {
                        width: '100%',
                        padding: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuContainer: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginTop: menu.kind === "dropdown" ? theme.spacing(1) : '0px',
                        marginBottom: menu.kind === "dropdown" ? theme.spacing(1) : '0px',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        maxWidth: '1280px',
                        marginTop: menu.kind === "dropdown" ? theme.spacing(3) : '0px',
                        marginBottom: menu.kind === "dropdown" ? theme.spacing(3) : '0px',
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3),
                    },
                    lg: {
                        maxWidth: '1280px',
                        marginTop: menu.kind === "dropdown" ? theme.spacing(3) : '0px',
                        marginBottom: menu.kind === "dropdown" ? theme.spacing(3) : '0px',
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3),
                    },
                    xl: {
                        maxWidth: '1280px',
                        marginTop: menu.kind === "dropdown" ? theme.spacing(3) : '0px',
                        marginBottom: menu.kind === "dropdown" ? theme.spacing(3) : '0px',
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3),
                    },
                },

                mainMenuLogoLink: {
                    common: {
                        textDecoration: 'none',
                        color: 'white',
                        marginRight: theme.spacing(4),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuLogoBox: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuLogoImg: {
                    common: {
                        width: 'auto',
                        height: '5rem',
                        backgroundColor: 'white',
                        borderRadius: '10%',
                        padding: theme.spacing(0.5),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuNav: {
                    common: {
                        width: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuUl: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        listStyleType: 'none',
                        margin: 0,
                        padding: 0,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuDropdowns: {
                    common: {
                        width: '100%',
                        height: 'fit-content',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        gap: theme.spacing(8),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuLabelsBox: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        paddingRight: theme.spacing(2),
                        paddingLeft: theme.spacing(2),
                        height: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuLabel1: {
                    common: {
                        whiteSpace: 'nowrap',
                        fontSize: '1rem',
                        fontFamily: 'Satisfy',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                mainMenuLabel2: {
                    common: {
                        whiteSpace: 'nowrap',
                        fontSize: '1.6rem',
                        fontWeight: 'bold',
                        fontFamily: 'Satisfy',
                        color: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        // Zone utile, c'est-à-dire avec des marges sur les écrans larges
        <Box
            maxWidth="lg"
            className={classes.mainMenuContainer}
        >
            {/* Logo et label (si existant) */}
            <Link
                className={classes.mainMenuLogoLink}
                to="/"
            >
                <Box className={classes.mainMenuLogoBox}>
                    {logo && logo.src &&
                        <img
                            className={classes.mainMenuLogoImg}
                            src={logo.src}
                            srcSet={logo.srcset}
                            sizes="10vw"
                            title={`${logo.legend} - ${logo.credits}`}
                            alt={logo.alt}
                        />
                    }

                    {params && (params.label1 || params.label2) &&
                        <Box className={classes.mainMenuLabelsBox}>
                            {params.label1 &&
                                <Typography className={classes.mainMenuLabel1}>
                                    {params.label1}
                                </Typography>
                            }
                            {params.label2 &&
                                <Typography className={classes.mainMenuLabel2}>
                                    {params.label2}
                                </Typography>
                            }
                        </Box>
                    }
                </Box>
            </Link>


            {menu.kind === "list" &&
                // Le menu doit être affiché sous forme de liste
                <Box
                    component='nav'
                    className={classes.mainMenuNav}
                >
                    <List
                        className={classes.mainMenuUl}
                    >
                        {menu.lst_items.map((menuItem) =>
                            <MainMenuList
                                key={menuItem.mainmenu.id}
                                menuItem={menuItem}
                                params={params}
                                style={style}
                            />
                        )}
                    </List>
                </Box>
            }


            {menu.kind === "dropdown" &&
                // Le menu doit être affiché via des 'dropdowns'
                <Box
                    className={classes.mainMenuDropdowns}
                >
                    {menu.lst_items.map((menuItem) =>
                        <MainMenuDropdown
                            key={menuItem.mainmenu.id}
                            menuItem={menuItem}
                            params={params}
                            style={style}
                        />
                    )}
                </Box>
            }

        </Box>
    );
}

MainMenu.propTypes = {
    logo: PropTypes.object,
    label: PropTypes.string,
    menu: PropTypes.object,
    params: PropTypes.object,
    style: PropTypes.object,
};
