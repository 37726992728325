import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Chip,
    Typography,
} from '@material-ui/core';

import {
    Timeline,
    TimelineItem,
    TimelineSeparator,
    TimelineConnector,
    TimelineContent,
    TimelineOppositeContent,
    TimelineDot,
} from '@material-ui/lab';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';

import {
    format as date_fns_format,
    parseISO,
} from "date-fns";
import { fr } from "date-fns/locale";

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useContentNewsListCardStyles = () => makeStyles((theme) => ({

    contentNewsListCard: style => ({
        ...style.contentNewsListCard.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCard.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCard.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCard.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCard.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCard.xl,
        },
    }),

    contentNewsListCardActionArea: style => ({
        ...style.contentNewsListCardActionArea.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardActionArea.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardActionArea.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardActionArea.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardActionArea.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardActionArea.xl,
        },
    }),

    contentNewsListCardMedia: style => ({
        ...style.contentNewsListCardMedia.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardMedia.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardMedia.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardMedia.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardMedia.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardMedia.xl,
        },
    }),

    contentNewsListCardContent: style => ({
        ...style.contentNewsListCardContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardContent.xl,
        },
    }),

    contentNewsListCardContentTitle: style => ({
        ...style.contentNewsListCardContentTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardContentTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardContentTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardContentTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardContentTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardContentTitle.xl,
        },
    }),

    contentNewsListCardContentSignatureDate: style => ({
        ...style.contentNewsListCardContentSignatureDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardContentSignatureDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardContentSignatureDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardContentSignatureDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardContentSignatureDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardContentSignatureDate.xl,
        },
    }),

    contentNewsListCardContentBoxChips: style => ({
        ...style.contentNewsListCardContentBoxChips.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardContentBoxChips.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardContentBoxChips.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardContentBoxChips.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardContentBoxChips.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardContentBoxChips.xl,
        },
    }),

    contentNewsListCardContentChipTag: style => ({
        ...style.contentNewsListCardContentChipTag.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListCardContentChipTag.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListCardContentChipTag.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListCardContentChipTag.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListCardContentChipTag.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListCardContentChipTag.xl,
        },
    }),

}));


//
// Composant permettant d'afficher une card contenant une actualité
//
export const ContentNewsListCard = ({ news, style, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useContentNewsListCardStyles()(
        lodashMerge(
            {
                contentNewsListCard: {
                    common: {
                        height: '100%',
                        textAlign: 'left',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNewsListCardActionArea: {
                    common: {
                        display: 'flex',
                        height: '100%',
                        flexDirection: 'column',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        flexDirection: 'row',
                        paddingRight: theme.spacing(2),
                    },
                    lg: {
                        flexDirection: 'row',
                        paddingRight: theme.spacing(2),
                    },
                    xl: {
                        flexDirection: 'row',
                        paddingRight: theme.spacing(2),
                    },
                },

                contentNewsListCardMedia: lodashMerge(
                    {
                        common: {
                            width: '100%',
                            height: '100%',
                            maxHeight: '10rem',
                            objectFit: 'cover',
                        },
                        xs: {},
                        sm: {},
                        md: {
                            width: '20vw',
                        },
                        lg: {
                            width: '20vw',
                        },
                        xl: {
                            width: '20vw',
                        },
                    },
                    (news && news.img_header && news.img_header.style) || {},
                ),

                contentNewsListCardContent: {
                    common: {
                        flex: 1,
                        height: '60%',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        height: '100%',
                    },
                    lg: {},
                    xl: {},
                },

                contentNewsListCardContentTitle: {
                    common: {
                        fontSize: '1.3rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNewsListCardContentSignatureDate: {
                    common: {
                        paddingTop: theme.spacing(1),
                        fontSize: '0.9rem',
                        opacity: '0.8',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNewsListCardContentBoxChips: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                        margin: theme.spacing(2, 0),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNewsListCardContentChipTag: {
                    common: {
                        marginRight: theme.spacing(1),
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.contentNewsListCard}>
            <CardActionArea
                className={classes.contentNewsListCardActionArea}
                component={Link}
                to={{ pathname: news.url_detail }}
            >
                {news.img_header &&
                    <CardMedia
                        className={classes.contentNewsListCardMedia}
                        component="img"
                        src={news.img_header.src}
                        srcSet={news.img_header.srcset}
                        sizes="50vw" // TODO : à calculer dynamiquement
                        title={`${news.img_header.legend} - ${news.img_header.credits}`}
                        alt={news.img_header.alt}
                    />
                }
                <CardContent
                    className={classes.contentNewsListCardContent}
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.contentNewsListCardContentTitle}
                    >
                        {news.title}
                    </Typography>

                    <Box className={classes.contentNewsListCardContentSignatureDate}>
                        {news.signature} - Publié le {news.date_publication_start && date_fns_format(parseISO(news.date_publication_start), 'EEEE dd MMMM yyyy', {locale: fr})}
                    </Box>

                    <Box className={classes.contentNewsListCardContentBoxChips}>
                        {news.tags.length > 0 && news.tags.split(";").map((tag, index) =>
                            <Chip
                                key={index}
                                className={classes.contentNewsListCardContentChipTag}
                                label={tag}
                                variant="outlined"
                            />
                        )}
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useContentNewsListStyles = () => makeStyles((theme) => ({

    contentNewsListRoot: style => ({
        ...style.contentNewsListRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListRoot.xl,
        },
    }),

    contentNewsListTitle: style => ({
        ...style.contentNewsListTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListTitle.xl,
        },
    }),

    contentNewsOppositeContent: style => ({
        ...style.contentNewsOppositeContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsOppositeContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsOppositeContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsOppositeContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsOppositeContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsOppositeContent.xl,
        },
    }),

    contentNewsListRootTail: style => ({
        ...style.contentNewsListRootTail.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentNewsListRootTail.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentNewsListRootTail.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentNewsListRootTail.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentNewsListRootTail.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentNewsListRootTail.xl,
        },
    }),

}));


//
// Composant permettant d'afficher la liste de toutes les actualités, via un slider
//
export const ContentNewsList = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useContentNewsListStyles()(
        lodashMerge(
            {
                contentNewsListRoot: {
                    common: {
                        width: '100%',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentNewsListTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNewsOppositeContent: {
                    common: {
                        flex: '0 1 0%',
                        padding: '0px',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                contentNewsListRootTail: {
                    common: {
                        backgroundColor: theme.palette.primary.main,
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentNewsListRoot}
        >
            {title &&
                <Typography
                    className={classes.contentNewsListTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data.length > 0 ?

                <Timeline align="left">
                    {data.map((news, index) => (
                        <TimelineItem key={index}>
                            <TimelineOppositeContent
                                className={classes.contentNewsOppositeContent}
                            />

                            <TimelineSeparator>
                                <TimelineDot color="primary">
                                    <ChatBubbleIcon />
                                </TimelineDot>

                                <TimelineConnector className={classes.contentNewsListRootTail} />
                            </TimelineSeparator>

                            <TimelineContent>

                                <ContentNewsListCard
                                    news={news}
                                    style={style}
                                />

                            </TimelineContent>
                        </TimelineItem>
                    ))}
                </Timeline>

            :
                <Typography>
                    Aucune actualité en ce moment
                </Typography>
            }
        </Box>
    );
}
