import React from 'react';
import PropTypes from 'prop-types';

import {
    useMemo,
} from 'react';

import {
    Link as RouterLink,
} from "react-router-dom";

import {
    Box,
    Button,
    Container,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    TextField,
    Typography,
} from '@material-ui/core';

import {
    Skeleton,
} from '@material-ui/lab';

import {
    makeStyles,
} from '@material-ui/core/styles';

import LocationOnIcon from '@material-ui/icons/LocationOn';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import WatchLaterIcon from '@material-ui/icons/WatchLater';
import DraftsIcon from '@material-ui/icons/Drafts';
import FacebookIcon from '@material-ui/icons/Facebook';
import YouTubeIcon from '@material-ui/icons/YouTube';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';


import { NewsletterFormEmail} from "PathWWW/components/NewsletterFormEmail.jsx";
import { formatPhoneNumber } from "PathCore/tools/utils.jsx";





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    footerRoot: {
        display: 'flex',
        flexDirection: 'column',
    },
    footerBoxInfos: {
        backgroundColor: theme.palette.primary[100],
    },
    footerContainerInfos: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footerTitre: {
        color: theme.palette.text.secondary,
        lineHeight: '3rem',
    },
    footerRSIcon: {
        width: 'auto',
        height: '2rem',
    },
    footerFormNewsletter: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: '1rem',
    },
    footerButtonNewsletter: {
        marginTop: '1rem',
        marginLeft: 'auto',
    },
    footerBoxCredits: {
        backgroundColor: theme.palette.primary[200],
    },
    footerContainerCredits: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    footerBoxCreditsCopyright: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerBoxCreditsLabel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    footerBoxCreditsLabelText: {
        width: '100%',
        textAlign: 'center',
    },
    footerBoxCreditsMentionsLegales: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));






const FooterContact = ({ address, address2, zipCode, city, phone, contactFormUrl, timeSlotsText }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <Typography
                variant="subtitle2"
                className={classes.footerTitre}
            >
                Contacts
            </Typography>
            <List
                disablePadding={true}
                dense={true}
                aria-label="Liste des moyens de contact : téléphone, adresse postale, adresse électronique et horaires"
            >
                {(address || address2 || zipCode || city) &&
                    <ListItem disableGutters={true}>
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <>
                                    {address && <span>{address}</span>}
                                    {address2 && <span>{address2}</span>}
                                    <span>{zipCode} {city}</span>
                                </>
                            }
                            primaryTypographyProps={{ style: { display: "flex", flexDirection: "column" } }}
                        />
                    </ListItem>
                }

                {phone &&
                    <ListItem disableGutters={true}>
                        <ListItemIcon>
                            <PhoneIcon />
                        </ListItemIcon>
                        <ListItemText primary={formatPhoneNumber(phone, ".")} />
                    </ListItem>
                }

                {contactFormUrl &&
                    <ListItem disableGutters={true}>
                        <ListItemIcon>
                            <EmailIcon />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Link
                                    component={RouterLink}
                                    to={
                                        {
                                            pathname: contactFormUrl,
                                            hash: "#formulaire-de-contact",
                                        }
                                    }
                                    target="_self"
                                >
                                    Nous contacter
                                </Link>
                            }
                        />
                    </ListItem>
                }

                {timeSlotsText &&
                    <ListItem disableGutters={true}>
                        <ListItemIcon>
                            <WatchLaterIcon />
                        </ListItemIcon>
                        <ListItemText
                            disableTypography={true}
                            primary={
                                timeSlotsText !== undefined ?
                                    <Box
                                        dangerouslySetInnerHTML={{ __html: timeSlotsText }}
                                    />
                                :
                                    <Skeleton variant="text" />
                            }
                        />
                    </ListItem>
                }
            </List>
        </>
    );

};

FooterContact.propTypes = {
    address: PropTypes.string,
    address2: PropTypes.string,
    zipCode: PropTypes.string,
    city: PropTypes.string,
    phone: PropTypes.string,
    timeSlotsText: PropTypes.string,
};





const FooterSocialNetworks = ({ lstLinks }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Si aucun lien passé en paramètre alors on n'affiche rien (pas même la section 'Réseaux sociaux')
    if( lstLinks.length < 1 ) {
        return null;
    }

    // S'il y a au moins un lien passé en paramètre
    return (
        <>
            <Typography
                variant="subtitle2"
                className={classes.footerTitre}
            >
                Réseaux sociaux
            </Typography>

            <List
                disablePadding={true}
                dense={true}
                aria-label="Liste des réseaux sociaux"
            >
                {lstLinks.map((link, index) => (
                    <ListItem
                        key={index}
                        disableGutters={true}
                    >
                        <ListItemIcon>
                            {link.logo.src &&
                                <img
                                    className={classes.footerRSIcon}
                                    src={link.logo.src}
                                    srcSet={link.logo.srcset}
                                    sizes="10vw"
                                    title={`${link.logo.legend} - ${link.logo.credits}`}
                                    alt={link.logo.alt}
                                />
                            }
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Link
                                    href={link.url}
                                    target="_blank"
                                    rel="noopener"
                                >
                                    {link.label}
                                </Link>
                            }
                        />
                    </ListItem>
                ))}
            </List>
        </>
    );
};

FooterSocialNetworks.propTypes = {
    lstLinks: PropTypes.array,
};





const FooterNewsletter = ({ newsletter }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    // Si aucune information liée à la newsletter n'est passée en paramètre
    // alors on n'affiche rien (pas même la section 'Newsletter')
    if( !(newsletter.infos || newsletter.subscription_enabled) ) {
        return null;
    }

    // S'il y a au moins une information passée en paramètre
    return (
        <>
            <Typography
                variant="subtitle2"
                className={classes.footerTitre}
            >
                Newsletter
            </Typography>

            {newsletter.infos &&
                <Typography variant="body2">{newsletter.infos}</Typography>
            }

            {newsletter.subscription_enabled &&
                <NewsletterFormEmail />
            }
        </>
    );
};

FooterNewsletter.propTypes = {
    newsletter: PropTypes.shape({
        infos: PropTypes.string,
        subscription_enabled: PropTypes.boolean,
    }),
};





const FooterCredits = ({ label }) => {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // On récupère l'année courante, pour l'afficher à côté du copyright
    const currentYear = new Date().getFullYear();



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.footerBoxCredits}
        >
            <Container
                maxWidth="lg"
                className={classes.footerContainerCredits}
            >
                <Grid container spacing={1}>

                    <Grid item xs={12} md={3}
                        className={classes.footerBoxCreditsCopyright}
                    >
                        <Typography>
                            ©&nbsp;{currentYear}&nbsp;-&nbsp;
                        </Typography>
                        <Link href="https://sites.eterritoire.com/">
                            eTerritoire
                        </Link>
                    </Grid>

                    <Grid item xs={12} md={6}
                        className={classes.footerBoxCreditsLabel}
                    >
                        <Typography
                            className={classes.footerBoxCreditsLabelText}
                            variant="body1"
                        >
                            {label ? label : <Skeleton variant="text" />}
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={3}
                        className={classes.footerBoxCreditsMentionsLegales}
                    >
                        <Link
                            component={RouterLink}
                            to="/mentions-legales/"
                            target="_self"
                        >
                            Mentions légales
                        </Link>
                    </Grid>

                </Grid>
            </Container>

        </Box>
    );
};

FooterCredits.propTypes = {
    label: PropTypes.string,
};






//
// Composant représentant le 'footer'.
// On y retrouve notamment :
// - les coordonnées de mairie
// - des liens vers les mentions légales ou le site d'eTerritoire
//
export const Footer = ({ footer }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.footerRoot}
        >
            <Box
                className={classes.footerBoxInfos}
            >
                <Container
                    maxWidth="lg"
                    className={classes.footerContainerInfos}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={6}>
                            {useMemo(() => (
                                <FooterContact
                                    address={footer && footer.address}
                                    address2={footer && footer.address2}
                                    zipCode={footer && footer.zip_code}
                                    city={footer && footer.city}
                                    phone={footer && footer.phone}
                                    contactFormUrl={footer && footer.contact_form_url}
                                    timeSlotsText={footer && footer.time_slots_text}
                                />
                            ), [
                                footer && footer.address,
                                footer && footer.address2,
                                footer && footer.zip_code,
                                footer && footer.city,
                                footer && footer.phone,
                                footer && footer.contact_form_url,
                                footer && footer.time_slots_text,
                            ])}
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {useMemo(() => (
                                <FooterSocialNetworks
                                    lstLinks={footer && footer.lst_social_network_links}
                                />
                            ), [
                                footer && footer.lst_social_network_links,
                            ])}
                        </Grid>

                        <Grid item xs={12} md={3}>
                            {useMemo(() => (
                                <FooterNewsletter
                                    newsletter={footer && footer.newsletter}
                                />
                            ), [
                                footer && footer.newsletter,
                            ])}
                        </Grid>
                    </Grid>

                </Container>
            </Box>

            {useMemo(() => (
                <FooterCredits
                    label={footer && footer.label}
                />
            ), [
                footer && footer.label,
            ])}

        </Box>
    );
}

Footer.propTypes = {
    footer: PropTypes.object,
};
