import React from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Box,
    Card,
    CardActionArea,
    CardContent,
    CardMedia,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
    useTheme,
} from '@material-ui/core/styles';

import format from 'date-fns/format'
import { fr } from 'date-fns/locale'

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    articlePreviewCard: style => ({
        ...style.articlePreviewCard.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewCard.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewCard.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewCard.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewCard.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewCard.xl,
        },
    }),

    articlePreviewCardActionArea: style => ({
        ...style.articlePreviewCardActionArea.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewCardActionArea.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewCardActionArea.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewCardActionArea.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewCardActionArea.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewCardActionArea.xl,
        },
    }),

    articlePreviewCardMedia: style => ({
        ...style.articlePreviewCardMedia.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewCardMedia.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewCardMedia.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewCardMedia.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewCardMedia.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewCardMedia.xl,
        },
    }),

    articlePreviewCardContent: style => ({
        ...style.articlePreviewCardContent.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewCardContent.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewCardContent.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewCardContent.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewCardContent.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewCardContent.xl,
        },
    }),

    articlePreviewTitle: style => ({
        ...style.articlePreviewTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewTitle.xl,
        },
    }),

    articlePreviewSignatureDate: style => ({
        ...style.articlePreviewSignatureDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewSignatureDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewSignatureDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewSignatureDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewSignatureDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewSignatureDate.xl,
        },
    }),


    articlePreviewSignature: style => ({
        ...style.articlePreviewSignature.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewSignature.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewSignature.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewSignature.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewSignature.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewSignature.xl,
        },
    }),


    articlePreviewDate: style => ({
        ...style.articlePreviewDate.common,

        [theme.breakpoints.only('xs')]: {
            ...style.articlePreviewDate.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.articlePreviewDate.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.articlePreviewDate.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.articlePreviewDate.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.articlePreviewDate.xl,
        },
    }),
}));





//
// Composant représentant un article présenté sous
// forme d'une carte.
//
export const ArticlePreviewCard = ({ article, ...props }) => {

    // TODO : actuellement, il n'est pas possible de paramètrer spécifiquement
    // le style de la 'PreviewCard'
    const style = {}


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const theme = useTheme();

    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                articlePreviewCard: {
                    common: {
                        height: '100%',
                        width: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                articlePreviewCardActionArea: {
                    common: {
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        gap: '1rem',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        height: '100%',
                        flexDirection: 'column',
                        gap: 'unset',
                    },
                    lg: {
                        height: '100%',
                        flexDirection: 'column',
                        gap: 'unset',
                    },
                    xl: {
                        height: '100%',
                        flexDirection: 'column',
                        gap: 'unset',
                    },
                },

                articlePreviewCardMedia: lodashMerge(
                    {
                        common: {
                            width: '100%',
                            height: '20vw',
                            maxWidth: '20vw',
                            objectFit: 'cover',
                        },
                        xs: {},
                        sm: {},
                        md: {
                            width: '100%',
                            height: '15rem',
                            minHeight: '15rem',
                            maxWidth: 'unset',
                        },
                        lg: {
                            width: '100%',
                            height: '15rem',
                            minHeight: '15rem',
                            maxWidth: 'unset',
                        },
                        xl: {
                            width: '100%',
                            height: '15rem',
                            minHeight: '15rem',
                            maxWidth: 'unset',
                        },
                    },
                    (article && article.img_header && article.img_header.style) || {},
                ),

                articlePreviewCardContent: {
                    common: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        width: '100%',
                        height: '100%',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                articlePreviewTitle: {
                    common: {
                        fontSize: '1.1rem',
                        fontWeight: 'bold',
                    },
                    xs: {},
                    sm: {},
                    md: {
                        fontSize: '1.3rem',
                    },
                    lg: {
                        fontSize: '1.3rem',
                    },
                    xl: {
                        fontSize: '1.3rem',
                    },
                },

                articlePreviewSignatureDate: {
                    common: {
                        paddingTop: theme.spacing(2),
                        opacity: '0.7',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                articlePreviewSignature: {
                    common: {
                        fontSize: '0.9rem',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },

                articlePreviewDate: {
                    common: {
                        fontSize: '0.9rem',
                        fontStyle: 'italic',
                    },
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Card className={classes.articlePreviewCard}>
            <CardActionArea
                className={classes.articlePreviewCardActionArea}
                component={Link}
                to={{ pathname: article.url_detail }}
            >
                <CardMedia
                    className={classes.articlePreviewCardMedia}
                    component="img"
                    src={article.img_header.src}
                    srcSet={article.img_header.srcset}
                    sizes="50vw" // TODO : à calculer dynamiquement (nb éléments dans la grille...)
                    title={`${article.img_header.legend} - ${article.img_header.credits}`}
                    alt={article.img_header.alt}
                />
                <CardContent
                    className={classes.articlePreviewCardContent}
                >
                    <Typography
                        component="h2"
                        variant="h5"
                        className={classes.articlePreviewTitle}
                    >
                        {article.title}
                    </Typography>

                    <Box className={classes.articlePreviewSignatureDate}>
                        <Typography
                            variant="body1"
                            className={classes.articlePreviewSignature}
                        >
                            {article.signature}
                        </Typography>

                        <Typography
                            variant="body1"
                            className={classes.articlePreviewDate}
                        >
                            {`Publié le ${format(new Date(article.date_publication_start), "EEEE dd MMMM yyyy", { locale: fr })}`}
                        </Typography>
                    </Box>
                </CardContent>
            </CardActionArea>
        </Card>
    );
}
