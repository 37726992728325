import React from 'react';

import {
    useState,
} from 'react';

import {
    Link,
} from "react-router-dom";

import {
    Backdrop,
    Box,
} from '@material-ui/core';

import {
    SpeedDial,
    SpeedDialAction,
    SpeedDialIcon,
} from '@material-ui/lab';

import {
    makeStyles
} from '@material-ui/core/styles';

import FlashOnIcon from '@material-ui/icons/FlashOn';
import CloseIcon from '@material-ui/icons/Close';


import {
    FAB_ACTIONS,
} from 'PathConfig/components/ProjectSettings.jsx'





// Style propre au composant
const useStyles = makeStyles((theme) => ({
    fond: {
        zIndex: 2000,
        backgroundColor: 'rgba(255, 255, 255, 0.95)',
    },
    speedDial: {
        zIndex: 2500,
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDialLink: {
        display: 'inherit',
        justifyContent: 'inherit',
        alignItems: 'inherit',
        color: 'inherit',
        textDecoration: 'none',
    },
    speedDialTooltipLabelNoMaxWidth: {
        minWidth: 'max-content',
        boxShadow: 'unset',
        color: theme.palette.text.primary,
    },
}));


/*
// Liste des actions (= boutons) contenues dans le "SpeedDial"
const SPEEDDIAL_ACTIONS = [
    { icon: <WorkIcon />, name: "Bulletins municipaux", url: "/" },
    { icon: <WorkIcon />, name: "Annuaire des associations", url: "/" },
    { icon: <WorkIcon />, name: "Annuaire des entreprises", url: "/" },
    { icon: <WorkIcon />, name: "Agenda", url: "/" },
    { icon: <WorkIcon />, name: "Actualités", url: "/" },
//    { icon: <WorkIcon />, name: "Météo", url: "/" },
    { icon: <WorkIcon />, name: "Ressource documentaire", url: "/" },
    { icon: <WorkIcon />, name: "Rechercher", url: "/" },
];
*/
/*
// Liste des actions (= boutons) contenues dans le "SpeedDial"
const FAB_ACTIONS = [
    { icon: <WorkIcon />, name: "Bulletins municipaux", url: "/" },
    { icon: <WorkIcon />, name: "Annuaire des associations", url: "/" },
    { icon: <WorkIcon />, name: "Annuaire des entreprises", url: "/" },
    { icon: <WorkIcon />, name: "Agenda", url: "/" },
    { icon: <WorkIcon />, name: "Actualités", url: "/" },
//    { icon: <WorkIcon />, name: "Météo", url: "/" },
    { icon: <WorkIcon />, name: "Ressource documentaire", url: "/" },
    { icon: <WorkIcon />, name: "Rechercher", url: "/" },
];
*/

//
// Composant représentant le bouton d'accès rapide,
// présent en bas à droite, sur tout le site.
//
export function FAB(props) {


    ///////////////////////////////////////////
    //                                       //
    //          Gestion des données          //
    //                                       //
    ///////////////////////////////////////////

    // Utilisé pour gérer l'ouverture et la fermeture du "SpeedDial"
    const [open, setOpen] = useState(false);



    //////////////////////////////////////////////
    //                                          //
    //          Gestion des événements          //
    //                                          //
    //////////////////////////////////////////////

    // Callback appelée lors de l'ouverture du "SpeedDial"
    const handleOpen = (event, reason) => {
        // On ne réagit qu'au clic (et non pas au survol)
        if(reason === "toggle") {
            setOpen(true);
        }
    };


    // Callback appelée lors de la fermeture du "SpeedDial"
    const handleClose = (event, reason) => {
        // On ne ferme pas le "Speed Dial" sur l'événement 'mouseLeave'...
        if(reason === "mouseLeave") {
            return;
        }

        // ... mais uniquement sur les événements 'toggle', 'blur' ou 'escapeKeyDown'
        setOpen(false);
    };



    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    const classes = useStyles();



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <>
            <Backdrop
                className={classes.fond}
                open={open}
            />
            <SpeedDial
                ariaLabel="Accès rapides"
                className={classes.speedDial}
                icon={
                    <SpeedDialIcon
                        icon={<FlashOnIcon />}
                        openIcon={<CloseIcon />}
                    />
                }
                onClose={handleClose}
                onOpen={handleOpen}
                open={open}
            >
                {FAB_ACTIONS.map((action, index) => (
                    <SpeedDialAction
                        key={index}
                        icon={
                            <Link
                                className={classes.speedDialLink}
                                to={{ pathname: action.url }}
                            >
                                {action.icon}
                            </Link>
                        }
                        tooltipTitle={
                            <Link
                                className={classes.speedDialLink}
                                to={{ pathname: action.url }}
                            >
                                {action.name}
                            </Link>
                        }
                        tooltipOpen
                        classes={{ staticTooltipLabel: classes.speedDialTooltipLabelNoMaxWidth }}
                        onClick={handleClose}
                    />
                ))}
            </SpeedDial>
        </>
    );
}
