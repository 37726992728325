// Configuration générale de la partie WWW

export const API_TOWN_HALL_WEB_URL = "/api/web/town-hall/";
export const API_CITY_WEB_URL = "/api/web/city/";
export const API_WEBSITE_WEB_URL = "/api/web/website/";
export const API_SLIDESHOW_PHOTOS_WEB_URL = "/api/web/slideshow-photos/";
export const API_DIRECT_LINK_WEB_URL = "/api/web/direct-link/";
export const API_CONTACT_WEB_URL = "/api/web/contact/";
export const API_STATIC_PAGE_FULL_URL = "/api/staticpage/";
export const API_NPORGANISATION_URL = "/api/nporganisation/";
export const API_NPORGANISATION_WEB_LIST_URL = "/api/nporganisation/weblist/";
export const API_ORGANISATION_URL = "/api/organisation/";
export const API_ORGANISATION_WEB_LIST_URL = "/api/organisation/weblist/";
export const API_RESOURCE_DOCUMENT_WEB_LIST_URL = "/api/resource/document/weblist/";

export const API_CONTENT_PAGE_WEB_URL = "/api/web/content-page/";
export const API_ARTICLE_WEB_URL = "/api/web/article/";
export const API_SEARCH_WEB_URL = "/api/web/search/"
export const API_RESOURCE_DOC_WEB_URL = "/api/web/resource-doc/"

export const API_CONTENT_PAGE_GET_FROM_URL_URL = "/api/content-page/get-from-url/";
export const API_HAPPENING_GET_BY_DATE_RANGE_URL = "/api/happening/get-by-date-range/";
export const API_CONTACT_FORM_URL = "/api/contact-form/";

export const API_SECTION_MENU_URL = "/api/section/menu/";
