import React from 'react';

import {
    Box,
    Typography,
} from '@material-ui/core';

import {
    makeStyles,
} from '@material-ui/core/styles';

import {
    merge as lodashMerge
} from 'lodash';





//
// Style propre au composant.
//
// ATTENTION : ici, il est nécessaire de définir 'useStyle' comme une fonction retournant
// le résultat de 'makestyle' et non pas directement comme le résultat de 'makestyle'. En
// effet, la valeur retournée par 'makeStyles' doit être recalculée à chaque rendu, sinon
// les valeurs associées aux "media queries" semblent ne pas être mises à jour mais
// uniquement la partie 'common' (les 'media query' semblent ne pas être recalculées)
//
const useStyles = () => makeStyles((theme) => ({

    contentResourcePhotoDetailRoot: style => ({
        ...style.contentResourcePhotoDetailRoot.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoDetailRoot.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoDetailRoot.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoDetailRoot.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoDetailRoot.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoDetailRoot.xl,
        },
    }),

    contentResourcePhotoDetailTitle: style => ({
        ...style.contentResourcePhotoDetailTitle.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoDetailTitle.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoDetailTitle.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoDetailTitle.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoDetailTitle.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoDetailTitle.xl,
        },
    }),

    contentResourcePhotoDetailImg: style => ({
        ...style.contentResourcePhotoDetailImg.common,

        [theme.breakpoints.only('xs')]: {
            ...style.contentResourcePhotoDetailImg.xs,
        },
        [theme.breakpoints.only('sm')]: {
            ...style.contentResourcePhotoDetailImg.sm,
        },
        [theme.breakpoints.only('md')]: {
            ...style.contentResourcePhotoDetailImg.md,
        },
        [theme.breakpoints.only('lg')]: {
            ...style.contentResourcePhotoDetailImg.lg,
        },
        [theme.breakpoints.only('xl')]: {
            ...style.contentResourcePhotoDetailImg.xl,
        },
    }),

}));



//
// Composant représentant une image
//
export const ContentResourcePhotoDetail = ({ title, params, style, data, ...props }) => {


    ////////////////////////////////////////
    //                                    //
    //          Gestion du style          //
    //                                    //
    ////////////////////////////////////////

    // On crée le style
    // On fusionne les styles récupérés avec un template par défaut
    // pour être sûr que toutes les propriétés soient disponibles
    // Les valeurs contenues dans le style 'style' (fournies par le serveur)
    // écraseront les valeurs par défaut
    //
    // ATTENTION : ici, 'useStyles' est une fonction retournant le résultat de
    // 'makeStyles' (retournant également une fonction) d'où le double appel.
    // Cette étape supplémentaire est nécessaire pour les valeurs associées aux
    // "media query" soient correctement mises à jour
    //
    const classes = useStyles()(
        lodashMerge(
            {
                contentResourcePhotoDetailRoot: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoDetailTitle: {
                    common: {},
                    xs: {},
                    sm: {},
                    md: {},
                    lg: {},
                    xl: {},
                },
                contentResourcePhotoDetailImg: lodashMerge(
                    {
                        common: {
                            width: "100%",
                            height: "100%",
                            objectFit: "contain",
                        },
                        xs: {},
                        sm: {},
                        md: {},
                        lg: {},
                        xl: {},
                    },
                    (data && data.style) || {},
                ),
            },
            style
        )
    );



    //////////////////////////////////////////
    //                                      //
    //          Rendu du composant          //
    //                                      //
    //////////////////////////////////////////

    return (
        <Box
            className={classes.contentResourcePhotoDetailRoot}
        >
            {title &&
                <Typography
                    className={classes.contentResourcePhotoDetailTitle}
                    component="h1"
                    variant="h1"
                >
                    {title}
                </Typography>
            }

            {data &&
                <img
                    className={classes.contentResourcePhotoDetailImg}
                    src={data.src}
                    srcSet={data.srcset}
                    sizes="100vw"
                    title={`${data.legend} - ${data.credits}`}
                    alt={data.alt}
                />
            }
        </Box>
    );
}
